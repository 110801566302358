import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore/lite";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBjN4QrNBMOLvDpQUYhokI9kxxRrlUYi9U",
  authDomain: "sinav-app-alm.firebaseapp.com",
  projectId: "sinav-app-alm",
  storageBucket: "sinav-app-alm.appspot.com",
  messagingSenderId: "719841548781",
  appId: "1:719841548781:web:c71528aaea65f178446fe4",
  measurementId: "G-5922X8SP4Q",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);

export { app, db };
