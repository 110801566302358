import logo from "./logo.svg";
import "./App.css";
import Homepage from "./pages/Homepage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Checkout from "./pages/Checkout";
import NotFoundPage from "./pages/NotFoundPage";
import SignIn from "./pages/AdminLogin";
import AdminPage from "./pages/AdminPage";
import ProtectedRoute from "./components/ProtectedRoute";
import Sonuc from "./pages/Sonuc";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Homepage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/checkout",
    element: <Checkout />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/adminlogin",
    element: <SignIn />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/admin",
    element: (
      <ProtectedRoute>
        <AdminPage />
      </ProtectedRoute>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: "/sonuc",
    element: <Sonuc />,
    errorElement: <NotFoundPage />,
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
