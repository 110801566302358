import React from "react";
import { Paper, useMediaQuery } from "@mui/material";

const SideBanners = () => {
  const imgSrcLeft = "banner1.jpg";
  const imgSrcRight = "banner2.jpg";
  const leftBannerLink = "https://forms.gle/p3cS8gnBuDCC3onc9"; // Replace with your desired URL
  const rightBannerLink = "https://forms.gle/p3cS8gnBuDCC3onc9"; // Replace with your desired URL

  const matches = useMediaQuery("(min-width:1400px)");

  if (!matches) {
    return null;
  }

  return (
    <>
      <a
        href={leftBannerLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          position: "fixed",
          top: "50%",
          left: 0,
          transform: "translateY(-50%)",
          zIndex: 1200,
          width: 160,
          height: "600px",
          display: "block",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            height: "100%",
            backgroundImage: `url(${imgSrcLeft})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </a>

      <a
        href={rightBannerLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          position: "fixed",
          top: "50%",
          right: 0,
          transform: "translateY(-50%)",
          zIndex: 1200,
          width: 160,
          height: "600px",
          display: "block",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            height: "100%",
            backgroundImage: `url(${imgSrcRight})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </a>
    </>
  );
};

export default SideBanners;
