import React from "react";
import { Link } from "react-router-dom";
import { Typography, Box, Button } from "@mui/material";

const NotFoundPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh", // Full viewport height
        textAlign: "center",
      }}
    >
      <Typography
        variant="h1"
        component="h2"
        gutterBottom
        sx={{ fontSize: "6rem", fontWeight: "bold" }}
      >
        404
      </Typography>
      <Typography variant="h5" sx={{ mb: 3 }}>
        Aradığınız sayfa bulunamadı, ana sayfaya dönmek için linke tıklayınız.
      </Typography>
      <Button
        variant="contained"
        component={Link}
        to="/"
        sx={{ textTransform: "none" }}
      >
        Ana Sayfaya Dön
      </Button>
    </Box>
  );
};

export default NotFoundPage;
