import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { LineChart, axisClasses } from "@mui/x-charts";

import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  Timestamp,
} from "firebase/firestore/lite";
import { db, app } from "../firebaseConfig";

import Title from "./Title";
import {
  Box,
  Grid,
  TextField,
  Typography,
  MenuItem,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/de";
import AddIcon from "@mui/icons-material/Add";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const querySnapshot = await getDocs(collection(db, "SinavBasliklari"));

const modulOpts = [];

const sehirOpts = [];

const seviyeOpts = [];

const sinavturuOpts = [];

const sinavyeriOpts = {};

function getDataFromFirestore() {
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    let values = null;

    if (doc.id === "moduller") {
      values = Object.values(data);
      modulOpts.push(...values);
    } else if (doc.id === "sehirler") {
      for (const [key, value] of Object.entries(data)) {
        // 'key' is the field name, 'value' is the field's value (which is an array in this case)
        sehirOpts.push(key); // Add the field name to sehirOpts
        const cityData = { [key]: [...value] }; // Use computed property names syntax

        Object.assign(sinavyeriOpts, cityData); // Spread operator to add all elements of the array to sinavyeriOpts
      }
    } else if (doc.id === "seviyeler") {
      values = Object.values(data);
      seviyeOpts.push(...values);
    } else if (doc.id === "sinavturleri") {
      values = Object.values(data);
      sinavturuOpts.push(...values);
    }
  });
}

getDataFromFirestore();

const AddExamForm = ({ onSuccessfulSubmit }) => {
  const [alertMessage, setAlertMessage] = React.useState("");

  const [alertOpen, setAlertOpen] = React.useState(false);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const [sadeceyazilisozlu, setSadeceYaziliSozlu] = React.useState(true);

  const handleSadeceYaziliSozluChange = (event) => {
    setSadeceYaziliSozlu(event.target.checked);
  };

  const [yaziliucret, setYaziliUcret] = React.useState("");

  const handleYaziliUcretChange = (event) => {
    setYaziliUcret(event.target.value);
  };

  const [sozluucret, setSozluUcret] = React.useState("");

  const handleSozluUcretChange = (event) => {
    setSozluUcret(event.target.value);
  };

  const [toplamucret, setToplamUcret] = React.useState("");

  const handleToplamUcretChange = (event) => {
    setToplamUcret(event.target.value);
  };

  const [kontenjan, setKontenjan] = React.useState("");

  const handleKontenjanChange = (event) => {
    setKontenjan(event.target.value);
  };

  const [sinavyeri, setSinavyeri] = React.useState("");

  const handleSinavYeriChange = (event) => {
    setSinavyeri(event.target.value);
  };

  const [tarih, setTarih] = React.useState(null);

  const handleTarihChange = (newDate) => {
    setTarih(newDate);
  };

  const [kayitBaslamaTarih, setKayitBaslamaTarih] = React.useState(null);

  const handleKayıtBaslamaTarihChange = (newDate) => {
    setKayitBaslamaTarih(newDate);
  };

  const [kayitBitisTarih, setKayitBitisTarih] = React.useState(null);

  const handleKayıtBitisTarihChange = (newDate) => {
    setKayitBitisTarih(newDate);
  };

  const [seviye, setSeviye] = React.useState("");

  const handleSeviyeChange = (event) => {
    setSeviye(event.target.value);
  };

  const [sinavturu, setSinavturu] = React.useState("");

  const handleSinavturuChange = (event) => {
    setSinavturu(event.target.value);
  };

  const [modul, setModul] = React.useState("");

  const handleModulChange = (event) => {
    setModul(event.target.value);
  };

  const [sehir, setSehir] = React.useState("");

  const handleSehirChange = (event) => {
    setSehir(event.target.value);
    setSinavyeri("");
  };

  const handleAddExam = async () => {
    if (
      !sinavyeri ||
      !tarih ||
      !kayitBaslamaTarih ||
      !kayitBitisTarih ||
      !seviye ||
      !sinavturu ||
      !modul ||
      !sehir ||
      !toplamucret ||
      !kontenjan
    ) {
      setAlertMessage(`Tüm alanların doldurulması zorunludur.`);
      setAlertOpen(true);
      return;
    }

    if (modul === "Yazılı/Sözlü" && !sadeceyazilisozlu) {
      if (modul === "Yazılı/Sözlü" && !yaziliucret) {
        setAlertMessage(`Yazılı ücret alanının doldurulması zorunludur.`);
        setAlertOpen(true);
        return;
      }

      if (modul === "Yazılı/Sözlü" && !sozluucret) {
        setAlertMessage(`Sözlü ücret alanının doldurulması zorunludur.`);
        setAlertOpen(true);
        return;
      }
    }

    const examData = {
      sinavyeri: sinavyeri,
      tarih: Timestamp.fromDate(new Date(tarih)), // Ensure this is properly formatted or converted if necessary
      kayitbaslama: Timestamp.fromDate(new Date(kayitBaslamaTarih)), // Same as above
      kayitbitis: Timestamp.fromDate(new Date(kayitBitisTarih)), // Same as above
      niveau: seviye,
      tur: sinavturu,
      modul: modul,
      sehir: sehir,
      fiyat: toplamucret,
      kontenjan: kontenjan,
      kayitliogrenciler: [],
      ...(sozluucret && { sozluucret: sozluucret }), // Only include if sozluucret has a truthy value
      ...(yaziliucret && { yaziliucret: yaziliucret }), // Only include if yaziliucret has a truthy value
    };

    try {
      // Add a new document with the prepared data to the 'Sinavlar' collection
      const docRef = await addDoc(collection(db, "Sinavlar"), examData);

      onSuccessfulSubmit();
      // Optionally, clear form or provide user feedback
      setSinavyeri("");
      setTarih(null);
      setKayitBaslamaTarih(null);
      setKayitBitisTarih(null);
      setSeviye("");
      setSinavturu("");
      setModul("");
      setSehir("");
      setToplamUcret("");
      setKontenjan("");
    } catch (e) {
      setAlertMessage(e);
      setAlertOpen(true);
    }
  };
  const theme = useTheme();

  return (
    <React.Fragment>
      <Title>Sınav Ekle</Title>
      <Box sx={{ width: "100%", flexGrow: 1, overflow: "hidden" }}>
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12} sm={6} md={4}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={"de"}
            >
              <DateTimePicker
                fullWidth
                label="Tarih ve Saat"
                inputFormat="DD/MM/YYYY HH:mm"
                variant="outlined"
                disablePast={true}
                onChange={handleTarihChange}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              sx={{ textAlign: "left" }}
              label="Seviye"
              variant="outlined"
              select
              value={seviye}
              onChange={handleSeviyeChange}
            >
              {seviyeOpts.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              sx={{ textAlign: "left" }}
              label="Sınav türü"
              variant="outlined"
              select
              value={sinavturu}
              onChange={handleSinavturuChange}
            >
              {sinavturuOpts.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              sx={{ textAlign: "left" }}
              label="Modül"
              variant="outlined"
              select
              value={modul}
              onChange={handleModulChange}
            >
              {modulOpts.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              sx={{ textAlign: "left" }}
              label="Şehir"
              variant="outlined"
              select
              value={sehir}
              onChange={handleSehirChange}
            >
              {sehirOpts.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={"de"}
            >
              <DatePicker
                fullWidth
                label="Kayıt Başlama Tarihi"
                variant="outlined"
                disablePast={true}
                onChange={handleKayıtBaslamaTarihChange}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={"de"}
            >
              <DatePicker
                fullWidth
                label="Kayıt Bitiş Tarihi"
                variant="outlined"
                disablePast={true}
                onChange={handleKayıtBitisTarihChange}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              label="Kontenjan"
              variant="outlined"
              value={kontenjan}
              onChange={handleKontenjanChange}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              sx={{ textAlign: "left" }}
              label="Sınav yeri"
              variant="outlined"
              select
              value={sinavyeri}
              onChange={handleSinavYeriChange}
              disabled={!sehir} // Disable until a city is selected
            >
              {!sehir && <MenuItem disabled>Select a city first</MenuItem>}
              {/* Conditionally render other MenuItems based on sehir */}
              {sehir &&
                sinavyeriOpts[sehir]?.map((yer) => (
                  <MenuItem key={yer} value={yer}>
                    {yer}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              label="Ücret (EUR)"
              variant="outlined"
              type="number"
              value={toplamucret}
              onChange={handleToplamUcretChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              disabled={
                modul === "Yazılı/Sözlü"
                  ? sadeceyazilisozlu === true
                    ? true
                    : false
                  : true
              }
              fullWidth
              label="Yazılı Ücret (EUR)"
              variant="outlined"
              type="number"
              value={yaziliucret}
              onChange={handleYaziliUcretChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              disabled={
                modul === "Yazılı/Sözlü"
                  ? sadeceyazilisozlu === true
                    ? true
                    : false
                  : true
              }
              fullWidth
              label="Sözlü Ücret (EUR)"
              variant="outlined"
              type="number"
              value={sozluucret}
              onChange={handleSozluUcretChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={sadeceyazilisozlu}
                  onChange={handleSadeceYaziliSozluChange}
                  name="sadeceYaziliSozlu"
                />
              }
              label="Sadece Yazılı+Sözlü"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#ff0000", color: "#FFF" }}
              onClick={handleAddExam}
              endIcon={<AddIcon />}
              size="large"
            >
              Sınavı Ekle
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {alertMessage}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default AddExamForm;
