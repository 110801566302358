import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Link, useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";

import { KeyboardArrowDown } from "@mui/icons-material";
import { KeyboardArrowUp } from "@mui/icons-material";

import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
} from "firebase/firestore/lite";
import { db, app } from "../firebaseConfig";

const sinavRows = [];

const querySnapshot = await getDocs(collection(db, "Ogrenciler"));

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
  p: 4,
};

function getDataFromFirestore() {
  querySnapshot.forEach((doc) => {
    const sinavObj = doc.data();

    const rowObj = createData(
      doc.id,
      sinavObj.tc,
      sinavObj.dogumtarihi,
      sinavObj.isim,
      sinavObj.soyisim,
      sinavObj.email,
      sinavObj.telno,
      sinavObj.adres1,
      sinavObj.sehir,
      sinavObj.zip,
      sinavObj.ulke,
      sinavObj.adres2,
      sinavObj.bolge,
      sinavObj.kayitlisinavlar
    );

    //console.log(doc.id);

    sinavRows.push(rowObj);
  });
}

getDataFromFirestore();

function createData(
  id,
  tc,
  dogumtarihi,
  isim,
  soyisim,
  email,
  telno,
  adres1,
  sehir,
  zip,
  ulke,
  adres2,
  bolge,
  kayitlisinavlar
) {
  return {
    id,
    tc,
    dogumtarihi,
    isim,
    soyisim,
    email,
    telno,
    adres1,
    sehir,
    zip,
    ulke,
    adres2,
    bolge,
    kayitlisinavlar,
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [currentRowData, setCurrentRowData] = React.useState(null);

  const [docIds, setDocIds] = React.useState([]);

  React.useEffect(() => {
    const fetchDocIds = async () => {
      const ids = await Promise.all(
        row.kayitlisinavlar.map(async (sinavObj) => {
          const docRef = sinavObj.ref;
          const kayitlioldugumodul = sinavObj.modul;
          const docSnap = await getDoc(sinavObj.ref); // Assuming `ref` is a Firestore document reference
          return { id: docSnap.id, modul: kayitlioldugumodul }; // Get document ID
        })
      );

      setDocIds(ids);
    };

    fetchDocIds();
  }, [row.kayitlisinavlar]); // Dependency array to re-run effect if `row.kayitlisinavlar` changes

  const handleUpdateOgrenci = (rowData) => {
    setCurrentRowData(rowData);
    setIsModalOpen(true); // Open the modal
  };

  return (
    <React.Fragment>
      <TableRow
        onClick={() => setOpen(!open)}
        hover
        sx={{ "& > *": { borderBottom: "unset" } }}
      >
        <TableCell>{row.isim}</TableCell>
        <TableCell>{row.soyisim}</TableCell>
        <TableCell>{row.tc}</TableCell>
        <TableCell>
          {row.dogumtarihi.toDate().toLocaleDateString("tr-TR", {
            //weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </TableCell>
        <TableCell>{row.email}</TableCell>
        <TableCell>{row.telno}</TableCell>
        <TableCell align="right">
          <Button
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: "#fafbfc",
          }}
          colSpan={7}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            //addEndListener={
            //  open ? console.log("acildi") : console.log("kapandi")
            //}
          >
            <Box sx={{ margin: 1 }}>
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                style={{ color: "#ff0000" }}
              >
                Detaylar
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Adres</TableCell>
                    <TableCell>Kayıtlı Sınavlar</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={"detaylar" + row.id}>
                    <TableCell component="th" scope="row">
                      {row.adres1 +
                        ", " +
                        row.adres2 +
                        ", " +
                        row.zip +
                        ", " +
                        row.sehir +
                        ", " +
                        row.bolge +
                        ", " +
                        row.ulke}
                    </TableCell>
                    <TableCell>
                      {docIds.map((sinavObj, index) => (
                        <React.Fragment key={index}>
                          {sinavObj.id + ", " + sinavObj.modul}
                          <br />
                        </React.Fragment>
                      ))}
                    </TableCell>

                    <TableCell align="right">
                      <Button
                        id="ToCheckout"
                        variant="contained"
                        style={{ backgroundColor: "#ff0000", color: "#FFF" }}
                        onClick={() => handleUpdateOgrenci(row)}
                      >
                        Öğrenci Bilgisi/Güncelle
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          {/* Modal content goes here, you can use currentRowData to display its information */}
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Çğrenci Detayları
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}></Typography>
          {/* Add more details from currentRowData as needed */}
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default function AdminOgrenciList() {
  return (
    <TableContainer
      component={Paper}
      sx={{ maxHeight: "100%", overflow: "auto" }}
    >
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>İsim</TableCell>
            <TableCell>Soyisim</TableCell>
            <TableCell>TC Kimlik</TableCell>
            <TableCell>Doğum Tarihi</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Telefon No</TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {sinavRows.map((row) => (
            <Row key={row.id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
