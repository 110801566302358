import * as React from "react";

import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { TextField, Autocomplete, MenuItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { FormControlLabel } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Checkbox } from "@mui/material";
import { KeyboardArrowUp } from "@mui/icons-material";

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import PrintIcon from "@mui/icons-material/Print";

import * as XLSX from "xlsx";

import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  doc,
  arrayUnion,
  updateDoc,
  arrayRemove,
  deleteDoc,
  Timestamp,
  orderBy,
  query,
} from "firebase/firestore/lite";
import { db, app } from "../firebaseConfig";

const sinavRows = [];

const sinavlarRef = collection(db, "Sinavlar");
const q = query(sinavlarRef, orderBy("tarih", "asc")); // Replace "date" with your field name
const querySnapshotSinavlar = await getDocs(q);

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
  p: 4,
};

function getDataFromFirestore() {
  querySnapshotSinavlar.forEach((doc) => {
    const sinavObj = doc.data();

    const rowObj = createData(
      doc.id,
      sinavObj.fiyat,
      sinavObj.kayitbaslama,
      sinavObj.kontenjan - sinavObj.kayitliogrenciler.length,
      sinavObj.modul,
      sinavObj.niveau,
      sinavObj.sehir,
      sinavObj.sinavyeri,
      sinavObj.tarih,
      sinavObj.tur,
      sinavObj.kayitbitis,
      sinavObj.kayitliogrenciler,
      sinavObj.kontenjan,
      sinavObj.kayitliogrenciler.length,
      sinavObj.yaziliucret,
      sinavObj.sozluucret
    );

    //console.log(doc.id);

    sinavRows.push(rowObj);
  });
}

getDataFromFirestore();

function createData(
  id,
  fiyat,
  kayitbaslama,
  kalankontenjan,
  modul,
  niveau,
  sehir,
  sinavyeri,
  tarih,
  tur,
  kayitbitis,
  kayitliogrenciler,
  toplamkontenjan,
  kayitliogrenci,
  yaziliucret,
  sozluucret
) {
  return {
    id,
    fiyat,
    kayitbaslama,
    kalankontenjan,
    modul,
    niveau,
    sehir,
    sinavyeri,
    tarih,
    tur,
    kayitbitis,
    kayitliogrenciler,
    toplamkontenjan,
    kayitliogrenci,
    yaziliucret,
    sozluucret,
  };
}

const querySnapshot = await getDocs(collection(db, "SinavBasliklari"));

const modulOpts = [];

const sehirOpts = [];

const seviyeOpts = [];

const sinavturuOpts = [];

const sinavyeriOpts = {};

function getBaslikDataFromFirestore() {
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    let values = null;

    if (doc.id === "moduller") {
      values = Object.values(data);
      modulOpts.push(...values);
    } else if (doc.id === "sehirler") {
      for (const [key, value] of Object.entries(data)) {
        // 'key' is the field name, 'value' is the field's value (which is an array in this case)

        sehirOpts.push(key); // Add the field name to sehirOpts
        const cityData = { key: [...value] }; // Use computed property names syntax
        Object.assign(sinavyeriOpts, cityData); // Spread operator to add all elements of the array to sinavyeriOpts
      }
    } else if (doc.id === "seviyeler") {
      values = Object.values(data);
      seviyeOpts.push(...values);
    } else if (doc.id === "sinavturleri") {
      values = Object.values(data);
      sinavturuOpts.push(...values);
    }
  });
}

getBaslikDataFromFirestore();

function Row(props) {
  const theme = useTheme();

  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const [selectedogrenci, setSelectedogrenci] = React.useState(null);

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const [kayitliogrecisayisi, setKayitliogrencisayisi] = React.useState(0);

  const [isVisible, setIsVisible] = React.useState(true);

  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = React.useState(false);

  const [yaziliChecked, setYaziliChecked] = React.useState(false);
  const [sozluChecked, setSozluChecked] = React.useState(false);

  const handleYaziliChange = (event) => {
    setYaziliChecked(event.target.checked);
  };

  const handleSozluChange = (event) => {
    setSozluChecked(event.target.checked);
  };

  const [alertInfo, setAlertInfo] = React.useState({
    open: false,
    message: "",
    type: "success", // Default to 'success', but can be 'error', 'warning', 'info'
  });

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertInfo({ ...alertInfo, open: false });
  };

  //THE EDIT EXAM PART START -------------------------------------------------------------

  const [yaziliucret, setYaziliUcret] = React.useState("");

  const handleYaziliUcretChange = (event) => {
    setYaziliUcret(event.target.value);
  };

  const [sozluucret, setSozluUcret] = React.useState("");

  const handleSozluUcretChange = (event) => {
    setSozluUcret(event.target.value);
  };

  const [toplamucret, setToplamUcret] = React.useState("");

  const handleToplamUcretChange = (event) => {
    setToplamUcret(event.target.value);
  };

  const [kontenjan, setKontenjan] = React.useState("");

  const handleKontenjanChange = (event) => {
    setKontenjan(event.target.value);
  };

  const [sinavyeri, setSinavyeri] = React.useState("");

  const handleSinavYeriChange = (event) => {
    setSinavyeri(event.target.value);
  };

  const [tarih, setTarih] = React.useState(null);

  const handleTarihChange = (newDate) => {
    setTarih(newDate);
  };

  const [kayitBaslamaTarih, setKayitBaslamaTarih] = React.useState(null);

  const handleKayıtBaslamaTarihChange = (newDate) => {
    setKayitBaslamaTarih(newDate);
  };

  const [kayitBitisTarih, setKayitBitisTarih] = React.useState(null);

  const handleKayıtBitisTarihChange = (newDate) => {
    setKayitBitisTarih(newDate);
  };

  const [seviye, setSeviye] = React.useState("");

  const handleSeviyeChange = (event) => {
    setSeviye(event.target.value);
  };

  const [sinavturu, setSinavturu] = React.useState("");

  const handleSinavturuChange = (event) => {
    setSinavturu(event.target.value);
  };

  const [modul, setModul] = React.useState("");

  const handleModulChange = (event) => {
    setModul(event.target.value);
  };

  const [sehir, setSehir] = React.useState("");

  const handleSehirChange = (event) => {
    setSehir(event.target.value);
    setSinavyeri("");
  };

  //THE EDIT EXAM PART END ---------------------------------------------------------------

  const handleOpenUpdateDialog = () => {
    setIsUpdateDialogOpen(true);
    setCurrentRowData(row); // Assuming you already have this state for current row data
  };

  // Function to close the update dialog
  const handleCloseUpdateDialog = () => {
    setIsUpdateDialogOpen(false);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const [currentRowData, setCurrentRowData] = React.useState(null);

  const handleUpdateExam = async () => {
    const examId = currentRowData?.id;
    const examRef = doc(db, "Sinavlar", examId); // Assuming 'db' is your Firestore database instance

    let updates = {};

    if (seviye) updates.niveau = seviye;
    if (sinavturu) updates.tur = sinavturu;
    if (modul) updates.modul = modul;
    if (sehir) updates.sehir = sehir;
    // Assuming 'kontenjan', 'sinavyeri', 'toplamucret', 'yaziliucret', and 'sozluucret' are your state variables for other fields
    if (kontenjan) updates.kontenjan = kontenjan;
    if (sinavyeri) updates.sinavyeri = sinavyeri;
    if (toplamucret) updates.fiyat = toplamucret;
    if (yaziliucret) updates.yaziliucret = yaziliucret;
    if (sozluucret) updates.sozluucret = sozluucret;
    if (tarih) updates.tarih = Timestamp.fromDate(new Date(tarih));
    if (kayitBaslamaTarih)
      updates.kayitbaslama = Timestamp.fromDate(new Date(kayitBaslamaTarih));
    if (kayitBitisTarih)
      updates.kayitbitis = Timestamp.fromDate(new Date(kayitBitisTarih));

    if (Object.keys(updates).length === 0) {
      setAlertInfo({
        open: true,
        message: "Hiçbir değişiklik algılanmadı.",
        type: "error",
      });
      return;
    }

    // Update the document
    try {
      await updateDoc(examRef, updates);
      setAlertInfo({
        open: true,
        message:
          "Sınav bilgileri başarıyla güncellenmiştir. Yenilikleri görmek için sayfayı yenileyiniz.",
        type: "success",
      });

      // Optionally, refresh your data or show a success message
    } catch (error) {
      setAlertInfo({
        open: true,
        message: error,
        type: "error",
      });
      // Handle errors, such as showing an error message
    }
  };
  const [kayitliOgrencilerDetails, setKayitliOgrencilerDetails] =
    React.useState([]);

  const [allOgrenciler, setAllOgrenciler] = React.useState([]);

  React.useEffect(() => {
    const fetchKayitliOgrencilerDetails = async () => {
      if (isModalOpen && row && row.kayitliogrenciler) {
        const details = await Promise.all(
          row.kayitliogrenciler.map(async (ogrenciObj) => {
            const ogrenciRef = ogrenciObj.ref; // Adjusted to access the ref from the object
            const ogrenciModul = ogrenciObj.modul; // Access the module info
            const docSnap = await getDoc(ogrenciRef);
            if (docSnap.exists()) {
              return {
                id: docSnap.id,
                modul: ogrenciModul, // Include the module info in the returned object
                ...docSnap.data(),
              };
            } else {
              return null;
            }
          })
        );
        setKayitliogrencisayisi(row.kayitliogrenciler.length);
        setKayitliOgrencilerDetails(
          details.filter((detail) => detail !== null)
        );
      }
    };

    const fetchAllOgrenciler = async () => {
      const querySnapshot = await getDocs(collection(db, "Ogrenciler"));
      const ogrenciler = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAllOgrenciler(ogrenciler);
    };

    if (isModalOpen) {
      fetchKayitliOgrencilerDetails();
      fetchAllOgrenciler();
    }
  }, [isModalOpen, row, db]);

  const handleUpdateOgrenciList = (rowData) => {
    setCurrentRowData(rowData);
    setIsModalOpen(true);
  };

  const handleAddOgrenciToExam = async () => {
    if (selectedogrenci && row) {
      const isStudentAlreadyRegistered = kayitliOgrencilerDetails.some(
        (ogrenci) => ogrenci.id === selectedogrenci.id
      );

      if (row.modul == "Yazılı" && sozluChecked) {
        setAlertInfo({
          open: true,
          message:
            "Bu sınav yazılı bir sınavdır. Lütfen sözlü seçimini kaldırınız.",
          type: "error",
        });
        return;
      } else if (row.modul == "Sözlü" && yaziliChecked) {
        setAlertInfo({
          open: true,
          message:
            "Bu sınav sözlü bir sınavdır. Lütfen yazılı seçimini kaldırınız.",
          type: "error",
        });
        return;
      }

      if (!yaziliChecked && !sozluChecked) {
        setAlertInfo({
          open: true,
          message: "Yazılı ya da Sözlü seçimini yapınız.",
          type: "error",
        });
        return;
      }

      let ogrencimodul = "";

      if (yaziliChecked) {
        ogrencimodul = "Yazılı";
      } else if (sozluChecked) {
        ogrencimodul = "Sözlü";
      }

      if (yaziliChecked && sozluChecked) {
        ogrencimodul = "Yazılı + Sözlü";
      }

      if (isStudentAlreadyRegistered) {
        setAlertInfo({
          open: true,
          message: "Bu öğrenci zaten bu sınava kayıtlı.",
          type: "error",
        });
      } else if (row.kayitliogrenci >= row.toplamkontenjan) {
        setAlertInfo({
          open: true,
          message:
            "Bu sınavın kontenjanı dolmuştur. Lütfen kontenjanı artırınız.",
          type: "error",
        });
      } else {
        const ogrenciRef = doc(db, "Ogrenciler", selectedogrenci.id);
        const examRef = doc(db, "Sinavlar", row.id);

        await updateDoc(examRef, {
          kayitliogrenciler: arrayUnion({
            ref: ogrenciRef,
            modul: ogrencimodul,
          }),
        });

        await updateDoc(ogrenciRef, {
          kayitlisinavlar: arrayUnion({ ref: examRef, modul: ogrencimodul }),
        })
          .then(() => {
            setKayitliOgrencilerDetails((currentDetails) => [
              ...currentDetails,
              {
                ...selectedogrenci,
                id: selectedogrenci.id,
                modul: ogrencimodul,
              },
            ]);
            setAlertInfo({
              open: true,
              message: "Öğrenci sınava kayıt edildi.",
              type: "success",
            });

            setSelectedogrenci("");
          })
          .catch((error) => {
            console.error(
              "Error adding exam to student's registered exams: ",
              error
            );
          });
      }
    } else {
      setAlertInfo({
        open: true,
        message: "Bir öğrenci seçiniz.",
        type: "error",
      });
    }
  };

  const handleRemoveOgrenciFromExam = async (ogrenciId) => {
    const examRef = doc(db, "Sinavlar", row.id); // Reference to the exam document
    const ogrenciRef = doc(db, "Ogrenciler", ogrenciId); // Reference to the student's document

    try {
      await updateDoc(examRef, {
        kayitliogrenciler: arrayRemove(ogrenciRef),
      });

      await updateDoc(ogrenciRef, {
        kayitlisinavlar: arrayRemove(examRef),
      });

      setKayitliOgrencilerDetails((currentDetails) =>
        currentDetails.filter((ogrenci) => ogrenci.id !== ogrenciId)
      );

      setAlertInfo({
        open: true,
        message: "Öğrencinin sınava kaydı silinmiştir.",
        type: "success",
      });
    } catch (error) {
      setAlertInfo({
        open: true,
        message: error,
        type: "error",
      });
    }
  };

  const handleDeleteExam = async (row) => {
    const examId = row.id;
    const examRef = doc(db, "Sinavlar", examId);

    try {
      // Fetch the exam document to get registered students
      const examSnap = await getDoc(examRef);
      if (examSnap.exists()) {
        const examData = examSnap.data();
        // Extract references from `kayitliogrenciler`
        const registeredStudents = examData.kayitliogrenciler || [];

        const studentRefs = registeredStudents.map((student) => {
          if (student.ref) {
            return student.ref;
          } else {
            throw new Error("Invalid student reference in kayitliogrenciler");
          }
        });

        // Remove exam reference from each registered student
        await Promise.all(
          studentRefs.map(async (ogrenciRef) => {
            // Fetch the student document to get current `kayitlisinavlar`
            const ogrenciSnap = await getDoc(ogrenciRef);
            if (ogrenciSnap.exists()) {
              const ogrenciData = ogrenciSnap.data();
              const currentExams = ogrenciData.kayitlisinavlar || [];

              // Filter out the exam to be deleted
              const updatedExams = currentExams.filter(
                (exam) => exam.ref.id !== examRef.id
              );

              console.log("updatedExams: ", updatedExams);

              updatedExams.forEach((exam) => {
                console.log(exam);
              });

              // Update the student's `kayitlisinavlar` array
              await updateDoc(ogrenciRef, {
                kayitlisinavlar: updatedExams,
              });
            }
          })
        );
      }

      // After removing references from all students, delete the exam
      await deleteDoc(examRef);
      setOpen(!open);
      setIsVisible(false); // Hide the row
      setAlertInfo({
        open: true,
        message: `${examId} ID'li sınav başarıyla silinmiştir.`,
        type: "success",
      });

      // Optionally, update UI here (e.g., remove the exam from the list)
    } catch (error) {
      console.error("Error processing delete: ", error);
      // Optionally, handle UI feedback here for error
      setAlertInfo({
        open: true,
        message: error.message || error.toString(),
        type: "error",
      });
    }
  };

  const downloadTableAsExcel = (kayitliOgrencilerDetails, row) => {
    // Transform the data for export
    const dataToExport = kayitliOgrencilerDetails.map((kayitliogrenci) => ({
      Cinsiyet: kayitliogrenci.cinsiyet,
      "İsim Soyisim": `${kayitliogrenci.isim} ${kayitliogrenci.soyisim}`,
      "TC Kimlik": kayitliogrenci.tc,
      "Doğum Tarihi": kayitliogrenci.dogumtarihi
        .toDate()
        .toLocaleDateString("tr-TR", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        }),
      "Doğum Yeri": kayitliogrenci.dogumyeri,
      "Kayıtlı Olduğu Modul": kayitliogrenci.modul,
    }));

    // Create a new workbook and worksheet with the transformed data
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Kayitli Ogrenciler");

    // Prepare the filename using the row's date
    let filenameDate = "Invalid-Date";
    if (row && row.tarih && row.tarih.toDate) {
      const formattedRowDate = row.tarih
        .toDate()
        .toLocaleDateString("tr-TR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/\//g, "-")
        .replace(/ /g, ""); // Ensure the format is filename-safe

      filenameDate = formattedRowDate;
    }

    const workbookName = `Sinav_${filenameDate}_${row.tur}_${row.niveau}_${
      row.id || "UnknownID"
    }.xlsx`;

    // Write the workbook to a file and initiate download
    XLSX.writeFile(workbook, workbookName);
  };

  return (
    <React.Fragment>
      {isVisible ? (
        <TableRow
          onClick={() => setOpen(!open)}
          hover
          sx={{ "& > *": { borderBottom: "unset" } }}
        >
          <TableCell>{row.id}</TableCell>
          <TableCell component="th" scope="row">
            {row.tarih.toDate().toLocaleString("tr-TR", {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "2-digit", // Add hour in 2-digit format
              minute: "2-digit", // Add minute in 2-digit format
              // You can also specify hour12: true or false if you want to force 12 or 24 hour time
            })}
          </TableCell>
          <TableCell>{row.niveau}</TableCell>
          <TableCell>{row.tur}</TableCell>
          <TableCell>{row.modul}</TableCell>
          <TableCell>{row.sehir}</TableCell>
          <TableCell align="right">
            {row.kayitbaslama.toDate().toLocaleDateString("tr-TR", {
              //weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </TableCell>
          <TableCell align="right">
            <Button
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </Button>
          </TableCell>
        </TableRow>
      ) : null}
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: "#fafbfc",
          }}
          colSpan={8}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            //addEndListener={
            //open ? console.log("acildi") : console.log("kapandi")
            //}
          >
            <Box sx={{ margin: 1 }}>
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                style={{ color: "#ff0000" }}
              >
                Detaylar
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Kayıt Bitiş</TableCell>
                    <TableCell>Toplam Kontenjan</TableCell>
                    <TableCell>Kayıtlı Öğrenci</TableCell>
                    <TableCell>Kalan Kontenjan</TableCell>
                    <TableCell>Sınav Konumu</TableCell>
                    <TableCell align="right">Ücret</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={"detaylar" + row.id}>
                    <TableCell component="th" scope="row">
                      {row.kayitbitis.toDate().toLocaleDateString("tr-TR", {
                        //weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </TableCell>
                    <TableCell>{row.toplamkontenjan}</TableCell>
                    <TableCell>{row.kayitliogrenci}</TableCell>
                    <TableCell>{row.kalankontenjan}</TableCell>
                    <TableCell>{row.sinavyeri}</TableCell>
                    <TableCell align="right">{row.fiyat + "€"}</TableCell>
                  </TableRow>
                  <TableRow key={"detaylar2" + row.id}>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell> </TableCell>
                    <TableCell>
                      <Button
                        id="ToOgrenciDetails"
                        variant="contained"
                        style={{ backgroundColor: "#ff0000", color: "#FFF" }}
                        onClick={() => handleDeleteExam(row)}
                      >
                        Sınavı Sil
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                    <TableCell component="th" scope="row" align="left">
                      <Button
                        id="ToUpdateExam"
                        variant="contained"
                        style={{ backgroundColor: "#ff0000", color: "#FFF" }}
                        onClick={handleOpenUpdateDialog}
                      >
                        Sınav Bilgilerini Güncelle
                      </Button>
                    </TableCell>
                    <TableCell align="left">
                      <Button
                        id="ToOgrenciDetails"
                        variant="contained"
                        style={{ backgroundColor: "#ff0000", color: "#FFF" }}
                        onClick={() => handleUpdateOgrenciList(row)}
                      >
                        Kayıtlı Öğrenciler/Öğrenci Ekle
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-dialog-title"
        aria-describedby="modal-dialog-description"
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle id="modal-dialog-title">
          <Typography variant="h4" gutterBottom component="div" sx={{ mt: 2 }}>
            Sınav Öğrenci İşlemleri
          </Typography>
          <Button
            id="ToPrintOgrenciList"
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#ff0000", color: "#FFF" }}
            onClick={() => downloadTableAsExcel(kayitliOgrencilerDetails, row)}
          >
            Excel'e Aktar
            <PrintIcon></PrintIcon>
          </Button>
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Box sx={{ width: "100%", mb: 2, p: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography variant="h5" gutterBottom color="primary">
                  Kayıtlı Öğrenciler
                </Typography>
                <Typography variant="h5" gutterBottom>
                  <span style={{ color: theme.palette.primary.main }}>
                    Kontenjan:
                  </span>{" "}
                  {row.kayitliogrenci}/{row.toplamkontenjan}
                </Typography>
              </Box>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>İsim Soyisim</TableCell>
                    <TableCell>TC Kimlik</TableCell>
                    <TableCell>Doğum Tarihi</TableCell>
                    <TableCell>Kayıtlı Olduğu Modul</TableCell>
                    <TableCell>Kaydı Sil</TableCell>
                  </TableRow>
                </TableHead>
                {/*kayitlioldugumodul*/}
                <TableBody>
                  {kayitliOgrencilerDetails.map((kayitliogrenci) => (
                    <TableRow key={kayitliogrenci.id}>
                      <TableCell>
                        {kayitliogrenci.isim + " " + kayitliogrenci.soyisim}
                      </TableCell>
                      <TableCell>{kayitliogrenci.tc}</TableCell>
                      <TableCell>
                        {kayitliogrenci.dogumtarihi
                          .toDate()
                          .toLocaleDateString("tr-TR", {
                            //weekday: "long",
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                          })}
                      </TableCell>
                      <TableCell>{kayitliogrenci.modul}</TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          color="error" // This will apply the theme's error color which is usually red
                          onClick={() =>
                            handleRemoveOgrenciFromExam(kayitliogrenci.id)
                          }
                        >
                          <DeleteOutlineOutlinedIcon></DeleteOutlineOutlinedIcon>
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
            <Box sx={{ width: "100%", mb: 2, p: 2 }}>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                color="primary"
              >
                Sınava Öğrenci Ekle
              </Typography>{" "}
              <Autocomplete
                sx={{ width: "50%", mb: 2, mt: 2 }}
                id="ogrenci-autocomplete"
                options={allOgrenciler}
                getOptionLabel={(option) => `${option.isim} ${option.soyisim}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Öğrenci Seç"
                    variant="outlined"
                  />
                )}
                // You can also handle selection changes using the onChange prop
                onChange={(event, value) => {
                  // value is the selected option
                  setSelectedogrenci(value);
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={yaziliChecked}
                    onChange={handleYaziliChange}
                  />
                }
                label="Yazılı"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sozluChecked}
                    onChange={handleSozluChange}
                  />
                }
                label="Sözlü"
              />
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>İsim Soyisim</TableCell>
                    <TableCell>TC Kimlik</TableCell>
                    <TableCell>Doğum Tarihi</TableCell>
                    <TableCell>Email</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedogrenci ? (
                    <TableRow key={selectedogrenci.id}>
                      <TableCell>
                        {selectedogrenci.isim + " " + selectedogrenci.soyisim}
                      </TableCell>
                      <TableCell>{selectedogrenci.tc}</TableCell>
                      <TableCell>
                        {selectedogrenci.dogumtarihi
                          .toDate()
                          .toLocaleDateString("tr-TR", {
                            //weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                      </TableCell>
                      <TableCell>{selectedogrenci.email}</TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
              <Button onClick={handleAddOgrenciToExam}>
                Öğrenciyi Sınava Ekle
              </Button>
            </Box>
            {/* You can add another Box here if you need another column */}
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isUpdateDialogOpen}
        onClose={handleCloseUpdateDialog}
        aria-labelledby="update-dialog-title"
        aria-describedby="update-dialog-description"
        fullWidth={true}
        maxWidth="sm" // Start with a base size, then adjust with sx for custom widths
      >
        <DialogTitle id="update-dialog-title">
          {"Sınav Bilgilerini Güncelle " + "ID: " + currentRowData?.id}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseUpdateDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" alignItems="center" gap={2}>
              <Typography variant="body1">
                Sağ tarafta gireceğeniz değerler sol taraftaki mevcut değerlerin
                yerine geçer. Eğer sağ tarafa hiçbir değer girmezseniz sol
                taraftaki değerler üzerinde hiçbir değişiklik yapılmaz.
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <Typography>
                Tarih:{" "}
                {currentRowData?.tarih.toDate().toLocaleString("tr-TR", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </Typography>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={"de"}
              >
                <DateTimePicker
                  sx={{ width: "50%" }}
                  label="Tarih"
                  variant="outlined"
                  disablePast={true}
                  onChange={handleTarihChange}
                />
              </LocalizationProvider>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <Typography>Seviye: {currentRowData?.niveau}</Typography>
              <TextField
                sx={{ textAlign: "left", width: "50%" }}
                label="Seviye"
                variant="outlined"
                select
                value={seviye}
                onChange={handleSeviyeChange}
              >
                {seviyeOpts.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <Typography>Sınav Türü: {currentRowData?.tur}</Typography>
              <TextField
                sx={{ textAlign: "left", width: "50%" }}
                label="Sınav türü"
                variant="outlined"
                select
                value={sinavturu}
                onChange={handleSinavturuChange}
              >
                {sinavturuOpts.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <Typography>Modül: {currentRowData?.modul}</Typography>
              <TextField
                sx={{ textAlign: "left", width: "50%" }}
                label="Modül"
                variant="outlined"
                select
                value={modul}
                onChange={handleModulChange}
              >
                {modulOpts.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <Typography>Şehir: {currentRowData?.sehir}</Typography>
              <TextField
                sx={{ textAlign: "left", width: "50%" }}
                label="Şehir"
                variant="outlined"
                select
                value={sehir}
                onChange={handleSehirChange}
              >
                {sehirOpts.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <Typography>
                Kayıt Başlama:{" "}
                {currentRowData?.kayitbaslama
                  .toDate()
                  .toLocaleDateString("tr-TR")}
              </Typography>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={"de"}
              >
                <DatePicker
                  sx={{ width: "50%" }}
                  label="Kayıt Başlama Tarihi"
                  variant="outlined"
                  disablePast={true}
                  onChange={handleKayıtBaslamaTarihChange}
                />
              </LocalizationProvider>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <Typography>
                Kayıt Bitiş:{" "}
                {currentRowData?.kayitbitis
                  .toDate()
                  .toLocaleDateString("tr-TR")}
              </Typography>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={"de"}
              >
                <DatePicker
                  sx={{ width: "50%" }}
                  label="Kayıt Bitiş Tarihi"
                  variant="outlined"
                  disablePast={true}
                  onChange={handleKayıtBitisTarihChange}
                />
              </LocalizationProvider>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <Typography>
                Toplam Kontenjan: {currentRowData?.toplamkontenjan}
              </Typography>
              <TextField
                sx={{ width: "50%" }}
                label="Kontenjan"
                variant="outlined"
                value={kontenjan}
                onChange={handleKontenjanChange}
                type="number"
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <Typography>Sınav Yeri: {currentRowData?.sinavyeri}</Typography>
              <TextField
                sx={{ textAlign: "left", width: "50%" }}
                label="Sınav yeri"
                variant="outlined"
                select
                value={sinavyeri}
                onChange={handleSinavYeriChange}
                //disabled={currentRowData?.sehir}
              >
                {sinavyeriOpts["key"].map((yer) => (
                  <MenuItem key={yer} value={yer}>
                    {yer}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <Typography>Fiyat: {currentRowData?.fiyat}€</Typography>
              <TextField
                sx={{ width: "50%" }}
                label="Ücret (EUR)"
                variant="outlined"
                type="number"
                value={toplamucret}
                onChange={handleToplamUcretChange}
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <Typography>
                Yazılı Ücret: {currentRowData?.yaziliucret}€
              </Typography>
              <TextField
                disabled={
                  currentRowData?.modul === "Yazılı/Sözlü" ? false : true
                }
                sx={{ width: "50%" }}
                label="Yazılı Ücret (EUR)"
                variant="outlined"
                type="number"
                value={yaziliucret}
                onChange={handleYaziliUcretChange}
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <Typography>
                Sözlü Ücret: {currentRowData?.sozluucret}€
              </Typography>
              <TextField
                disabled={
                  currentRowData?.modul === "Yazılı/Sözlü" ? false : true
                }
                sx={{ width: "50%" }}
                label="Sözlü Ücret (EUR)"
                variant="outlined"
                type="number"
                value={sozluucret}
                onChange={handleSozluUcretChange}
              />
            </Box>
            <Box display="flex" alignItems="right" gap={2}>
              <Button
                onClick={handleUpdateExam}
                id="UpdateExamDetails"
                variant="contained"
                style={{ backgroundColor: "#ff0000", color: "#FFF" }}
              >
                Sınav Bilgilerini Güncelle
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={alertInfo.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Alert onClose={handleCloseAlert} severity={alertInfo.type}>
          {alertInfo.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default function AdminSinavList() {
  return (
    <TableContainer
      component={Paper}
      sx={{ maxHeight: "100%", overflow: "auto" }}
    >
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Tarih</TableCell>
            <TableCell>Seviye</TableCell>
            <TableCell>Sınav Türü</TableCell>
            <TableCell>Modül</TableCell>
            <TableCell>Şehir</TableCell>
            <TableCell align="right">Kayıt Başlama</TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {sinavRows.map((row) => (
            <Row key={row.id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
