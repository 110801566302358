import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { getAuth, signOut } from "firebase/auth";
import Button from "@mui/material/Button";

const LogoutButton = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  const auth = getAuth();

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        //console.log("User signed out successfully");
        navigate("/adminlogin"); // Redirect to /adminlogin after successful logout
      })
      .catch((error) => {
        //console.error("Error signing out:", error);
      });
  };

  return (
    <Button
      variant="contained"
      style={{ backgroundColor: "#ff0000", color: "#FFF" }}
      onClick={handleLogout}
    >
      Logout
    </Button>
  );
};

export default LogoutButton;
