import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { LineChart, axisClasses } from "@mui/x-charts";

import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  Timestamp,
} from "firebase/firestore/lite";
import { db, app } from "../firebaseConfig";

import Title from "./Title";
import {
  Box,
  Grid,
  TextField,
  Typography,
  MenuItem,
  Button,
  Container,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/de";
import AddIcon from "@mui/icons-material/Add";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const AddOgrenciForm = ({}) => {
  const [alertMessage, setAlertMessage] = React.useState("");

  const [alertOpen, setAlertOpen] = React.useState(false);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const [tc, setTc] = React.useState("");

  const handleTcChange = (event) => {
    setTc(event.target.value);
  };

  const [dogumtarihi, setDogumtarihi] = React.useState("");

  const handleDogumtarihiChange = (newDate) => {
    setDogumtarihi(newDate);
  };

  const [isim, setIsim] = React.useState("");

  const handleIsimChange = (event) => {
    setIsim(event.target.value);
  };

  const [soyisim, setSoyisim] = React.useState("");

  const handleSoyisimChange = (event) => {
    setSoyisim(event.target.value);
  };

  const [email, setEmail] = React.useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const [telno, setTelno] = React.useState("");

  const handleTelnoChange = (event) => {
    setTelno(event.target.value);
  };

  const [adres1, setAdres1] = React.useState("");

  const handleAdres1Change = (event) => {
    setAdres1(event.target.value);
  };

  const [adres2, setAdres2] = React.useState("");

  const handleAdres2Change = (event) => {
    setAdres2(event.target.value);
  };

  const [modul, setModul] = React.useState("");

  const handleModulChange = (event) => {
    setModul(event.target.value);
  };

  const [sehir, setSehir] = React.useState("");

  const handleSehirChange = (event) => {
    setSehir(event.target.value);
  };

  const [bolge, setBolge] = React.useState("");

  const handleBolgeChange = (event) => {
    setBolge(event.target.value);
  };

  const [zip, setZip] = React.useState("");

  const handleZipChange = (event) => {
    setZip(event.target.value);
  };

  const [ulke, setUlke] = React.useState("");

  const handleUlkeChange = (event) => {
    setUlke(event.target.value);
  };

  const handleAddOgrenci = async () => {
    // Check if any of the required fields are empty or null
    if (
      !tc ||
      !dogumtarihi ||
      !isim ||
      !soyisim ||
      !email ||
      !telno ||
      !adres1 ||
      !sehir ||
      !zip ||
      !ulke
    ) {
      // Alert or display a message that all fields are required
      setAlertMessage(` * ile işaretli alanların doldurulması zorunludur.`);
      setAlertOpen(true); // You might want to replace this with a more user-friendly UI feedback
      return; // Exit the function to prevent further execution
    }

    // If all fields are filled, proceed with form submission or further processing
    const ogrenciData = {
      tc: tc,
      dogumtarihi: Timestamp.fromDate(new Date(dogumtarihi)), // Ensure this is properly formatted or converted if necessary
      isim: isim, // Same as above
      soyisim: soyisim, // Same as above
      email: email,
      telno: telno,
      adres1: adres1,
      sehir: sehir,
      zip: zip,
      ulke: ulke,
      kayitlisinavlar: [],
      adres2: adres2,
      bolge: bolge,
    };

    try {
      // Add a new document with the prepared data to the 'Sinavlar' collection
      const docRef = await addDoc(collection(db, "Ogrenciler"), ogrenciData);
      //console.log("Document written with ID: ", docRef.id);

      // Optionally, clear form or provide user feedback
      setTc("");
      setIsim("");
      setDogumtarihi(null);
      setSoyisim("");
      setEmail("");
      setTelno("");
      setAdres1("");
      setAdres2("");
      setSehir("");
      setZip("");
      setUlke("");
      setAdres2("");
      setBolge("");
    } catch (e) {
      //console.error("Error adding document: ", e);
      // Handle the error (e.g., user feedback)
    }
  };
  const theme = useTheme();

  return (
    <React.Fragment>
      <Title>Öğrenci Ekle</Title>
      <Box sx={{ width: "100%", flexGrow: 1, overflow: "hidden" }}>
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              required
              label="Tc Kimlik Numarası"
              variant="outlined"
              value={tc}
              onChange={handleTcChange}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={"de"}
              fullWidth
            >
              <DatePicker
                fullWidth
                label="Doğum Tarihi *"
                variant="outlined"
                disablePast={false}
                onChange={handleDogumtarihiChange}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              required
              label="İsim"
              variant="outlined"
              value={isim}
              onChange={handleIsimChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              required
              label="Soyisim"
              variant="outlined"
              value={soyisim}
              onChange={handleSoyisimChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              required
              label="Email"
              variant="outlined"
              value={email}
              onChange={handleEmailChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              required
              label="Telefon No"
              variant="outlined"
              value={telno}
              onChange={handleTelnoChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              label="Adres 1"
              required
              variant="outlined"
              value={adres1}
              onChange={handleAdres1Change}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              label="Adres 2"
              variant="outlined"
              value={adres2}
              onChange={handleAdres2Change}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              label="Şehir"
              required
              variant="outlined"
              value={sehir}
              onChange={handleSehirChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              label="Bölge/Eyalet"
              variant="outlined"
              value={bolge}
              onChange={handleBolgeChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              required
              label="ZIP/Posta kodu"
              variant="outlined"
              value={zip}
              onChange={handleZipChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              required
              label="Ülke"
              variant="outlined"
              value={ulke}
              onChange={handleUlkeChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddOgrenci}
              endIcon={<AddIcon />}
              size="large"
            >
              Öğrenci Ekle
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {alertMessage}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default AddOgrenciForm;
