import React from "react";
import { Box, Typography, Grid, Fade } from "@mui/material";
import { generatePath } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { alpha } from "@mui/material";
import { Link } from "@mui/material";

const GenerateSections = (item, index) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger animation only once
    rootMargin: "-200px 0px", // Trigger when the item comes within 100px of the viewport
  });

  // Define text content
  const textcontent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        align={index % 2 === 0 ? "left" : "right"}
        variant="h4"
        sx={{ mb: 1 }}
      >
        {item.header}
      </Typography>
      {item.bulletpoints && (
        <Box sx={{ mt: 2 }}>
          {item.bulletpoints.map((bullet, idx) => (
            <Typography
              key={idx}
              variant="body2"
              component="div"
              align={index % 2 === 0 ? "left" : "right"}
              sx={{ mt: 2 }}
            >
              • {bullet}
            </Typography>
          ))}
        </Box>
      )}
      {item.mundlich && (
        <Typography
          variant="body2"
          align={index % 2 === 0 ? "left" : "right"}
          component="div"
          sx={{ mt: 2 }}
        >
          <Box component="span" sx={{ textDecoration: "underline" }}>
            {item.header}
          </Box>
          : {item.mundlich}
        </Typography>
      )}

      {item.schriftlich && (
        <Typography
          variant="body2"
          align={index % 2 === 0 ? "left" : "right"}
          component="div"
          sx={{ mt: 2 }}
        >
          <Box component="span" sx={{ textDecoration: "underline" }}>
            {item.header}
          </Box>
          : {item.schriftlich}
        </Typography>
      )}
      <Typography
        variant="body2"
        align={index % 2 === 0 ? "left" : "right"}
        sx={{ mt: 5 }}
      >
        {item.text}
      </Typography>
      {item.doc && (
        <Typography
          variant="caption"
          align={index % 2 === 0 ? "left" : "right"}
          sx={{ opacity: 0.8, mt: 5 }}
        >
          {item.header} örnek deneme sınavı için&nbsp;
          <a
            href={item.doc}
            download
            style={{ textDecoration: "none", color: "#1976d2" }} // Use your theme's primary color
          >
            Tıklayınız
          </a>
        </Typography>
      )}
    </Box>
  );

  // Define image content
  const imagecontent = (
    <Box
      sx={{
        width: 400,
        height: 300,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        outline: "1px solid",
        outlineColor: alpha("#ff0000", 0.2),
        boxShadow: `0 0 13px 8px ${alpha("#000000", 0.1)}`,
        overflow: "hidden", // Ensure the image does not overflow the container
      }}
    >
      {item.img && (
        <img
          src={item.img}
          alt={item.header}
          style={{
            width: "100%", // Make image fill the container width
            height: "auto", // Adjust height automatically to keep aspect ratio
            maxWidth: "100%", // Ensure image does not exceed its natural size
          }}
        />
      )}
    </Box>
  );

  // Grid container for layout
  let finalcontent = (
    <Grid
      container
      spacing={6}
      key={index}
      ref={ref}
      sx={{
        mt: 0,
        display: "flex",
        flexDirection: index % 2 === 0 ? "row" : "row-reverse",
        opacity: inView ? 1 : 0,
        transform: inView
          ? "translateX(0)"
          : index % 2 === 0
          ? "translateX(-100px)"
          : "translateX(-100px)",
        transition: "opacity 0.6s ease-out, transform 0.6s ease-out",
      }}
    >
      {/* Image Grid */}
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          display: "flex",
          justifyContent: index % 2 === 0 ? "flex-start" : "flex-end",
          alignItems: "center",
        }}
      >
        {imagecontent}
      </Grid>
      {/* Text Grid */}
      <Grid item xs={12} sm={6}>
        {textcontent}
      </Grid>
    </Grid>
  );

  return finalcontent;
};

const SinavAciklamalari = ({ items }) => {
  return (
    <Box
      sx={{
        mt: { xs: 1, sm: 3 },
        width: "100%",
        //backgroundImage: "linear-gradient(180deg, #CEE5FD, #FFF)",
        backgroundImage:
          "linear-gradient( rgba(255, 255, 255, 1),rgba(230, 235, 255, 1)), url('06.jpg')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Box
        sx={{
          width: { xs: "90%", sm: "80%", md: "80%", lg: 1000 },
          p: { xs: 2, sm: 8 },
          margin: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h3" id="sinavhakkinda">
          telc Almanca Sınav Seviyeleri ve Deneme Sınavları
        </Typography>
        {items.map((item, index) => GenerateSections(item, index))}
      </Box>
    </Box>
  );
};

export default SinavAciklamalari;
