import React from "react";

import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import SinavAciklamalari from "../components/SinavAciklamalari";
import SinavBilgileri from "../components/SinavBilgileri";
import SideBanners from "../components/SideBanners";

const items = [
  {
    header: "telc Deutsch A1 Dijital",
    bulletpoints: [
      "telc A1 sınavı Yazılı ve Sözlü olmak üzere 2 kısımdan oluşur. Yazılı bölüm 65 dakika sürer. Yazılı bölümden hemen sonra katılımcıların sözlü bölümleri başlar. Yazılı ve sözlü sınavlar aynı gün yapılır.",
      "telc A1 sınavı 60 puan üzerinden değerlendirilir. Geçme notu minimum 36'dır.",
    ],
    text: "",
    img: "a1.jpg",
    doc: "a1.pdf",
  },
  {
    header: "telc Deutsch A2",
    bulletpoints: [
      "telc A2 sınavı Yazılı ve Sözlü olmak üzere 2 kısımdan oluşur. Yazılı bölüm 70 dakika sürer. Yazılı bölümden hemen sonra katılımcıların sözlü bölümleri başlar. Yazılı ve sözlü sınavlar aynı gün yapılır.",
      "telc A2 sınavı 60 puan üzerinden değerlendirilir. Geçme notu minimum 36'dır.",
    ],
    text: "",
    img: "a2digital.png",
    doc: "a2.pdf",
  },
  {
    header: "telc Deutsch B1 Dijital",
    bulletpoints: [
      "telc B1 sınavı Yazılı ve Sözlü olmak üzere 2 kısımdan oluşur. Sınav dijital platformda bilgisayar üzerinden gerçekleştirilir. Yazılı bölüm 2 saat 30 dakika sürer. Sözlü sınavdan önce katılımcılara 20 dakika hazırlık süresi verilir. Yazılı ve sözlü sınavlar aynı gün yapılır.",
      "telc B1 sınavı 300 puan üzerinden değerlendirilir. Schriftlich ve Mündlich bölümleri kendi içinde ayrı olarak puanlandırılır.",
    ],
    text: "",
    mundlich:
      "75 puan üzerinden değerlendirilir. Minimum 45 puan alan kişiler sınavda başarılı olur.",

    schriftlich:
      "225 puan üzerinden değerlendirilir. Minimum 135 puan alan kişiler sınavda başarılı olur.",
    img: "b1-1.jpg",
    doc: "b1.pdf",
  },
  {
    header: "telc Deutsch B2 Dijital",
    bulletpoints: [
      "telc B2 sınavı Yazılı ve Sözlü olmak üzere 2 kısımdan oluşur. Sınav dijital platformda bilgisayar üzerinden gerçekleştirilir. Yazılı bölüm 2 saat 20 dakika sürer. Sözlü sınavdan önce katılımcılara 20 dakika hazırlık süresi verilir. Yazılı ve sözlü sınavlar aynı gün yapılır.",
      "telc B2 sınavı 300 puan üzerinden değerlendirilir. Schriftlich ve Mündlich bölümleri kendi içinde ayrı olarak puanlandırılır.",
    ],
    text: "",
    mundlich:
      "75 puan üzerinden değerlendirilir. Minimum 45 puan alan kişiler sınavda başarılı olur.",
    schriftlich:
      "225 puan üzerinden değerlendirilir. Minimum 135 puan alan kişiler sınavda başarılı olur.",
    img: "b2-1.jpg",
    doc: "b2.pdf",
  },
  {
    header: "telc Deutsch C1",
    bulletpoints: [
      "telc C1 sınavı Yazılı ve Sözlü olmak üzere 2 kısımdan oluşur. Sınav dijital platformda bilgisayar üzerinden gerçekleştirilir. Yazılı bölüm 20 dk arayla birlikte toplamda 3 saat 30 dakika sürer. Sözlü sınavdan önce katılımcılara 20 dakika hazırlık süresi verilir. Yazılı ve Sözlü sınavlar aynı gün yapılır.",
      "telc C1 sınavı 214 puan üzerinden değerlendirilir. Schriftlich ve Mündlich bölümleri kendi içlerinde ayrı olarak puanlandırılır.",
    ],
    text: "",
    mundlich:
      "48 puan üzerinden değerlendirilir. Minimum 29 puan alan kişiler sınavda başarılı olur.",
    schriftlich:
      "166 puan üzerinden değerlendirilir. Minimum 99 puan alan kişiler sınavda başarılı olur.",
    img: "c1.jpg",
    doc: "",
  },
  {
    header: "telc Deutsch C1 Hochschule Dijital",
    bulletpoints: [
      "Üniversite başvurusu yapacak olan adayların girmesi gereken sınavdır. telc C1 Hochschule sınavı Almanya'da tüm üniversiteler ve Yüksekokullar tarafından tanınır.",
      "telc C1 Hochschule sınavı Yazılı ve Sözlü olmak üzere 2 kısımdan oluşur. Sınav dijital platformda bilgisayar üzerinden gerçekleştirilir. Yazılı bölüm 20 dk arayla birlikte toplamda 3 saat 30 dakika sürer. Sözlü sınavdan önce katılımcılara 20 dakika hazırlık süresi verilir. Yazılı ve Sözlü sınavlar aynı gün yapılır.",
      "telc C1 Hochschule sınavı 214 puan üzerinden değerlendirilir. Schriftlich ve Mündlich bölümleri kendi içlerinde ayrı olarak puanlandırılır.",
    ],
    text: "",
    mundlich:
      "48 puan üzerinden değerlendirilir. Minimum 29 puan alan kişiler sınavda başarılı olur.",
    schriftlich:
      "166 puan üzerinden değerlendirilir. Minimum 99 puan alan kişiler sınavda başarılı olur.",
    img: "c1hoh.jpg",
    doc: "",
  },
];

const Homepage = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <SideBanners />
      <SinavAciklamalari items={items} />
      <SinavBilgileri />
      <Footer />
    </div>  
  );
};

export default Homepage;
