import * as React from "react";
import { useState, useRef, forwardRef, useImperativeHandle } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateField } from "@mui/x-date-pickers";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { json } from "react-router-dom";
import Link from "@mui/material/Link";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { FormGroup } from "@mui/material";
import "dayjs/locale/tr"; // Import the locale you want to use
import dayjs from "dayjs";

const labels = ["TC Kimlik No", "Pasaport No"];

const AddressForm = forwardRef((props, ref) => {
  const [placeofbirth, setPlaceofbirth] = useState(null);

  const [gender, setGender] = useState("bay");

  const [ssaccepted, setSsAccepted] = useState(false);

  const [kvkkaccepted, setKvkkaccepted] = useState(false);

  const [satisaccepted, setSatisaccepted] = useState(false);

  const [isTcVatandasi, setIsTcVatandasi] = useState(true);

  const [tc, setTc] = useState(null);

  const [dateofbirth, setDateOfBirth] = useState(null);

  const [name, setName] = useState(null);

  const [surname, setSurname] = useState(null);

  const [email, setEmail] = useState(null);

  const [phone, setPhone] = useState(null);

  const [address1, setAddress1] = useState(null);

  const [address2, setAddress2] = useState(null);

  const [city, setCity] = useState(null);

  const [province, setProvince] = useState(null);

  const [zip, setZip] = useState(null);

  const [country, setCountry] = useState(null);

  const [alertOpen, setAlertOpen] = useState(false);

  const [alertMessage, setAlertMessage] = useState("");

  const [openSS, setOpenSS] = useState(false);

  const [openSatis, setOpenSatis] = useState(false);

  const [openKVKK, setOpenKVKK] = useState(false);

  const handleOpenSS = () => {
    setOpenSS(true);
  };

  const handleCloseSS = () => {
    setOpenSS(false);
  };

  const handleOpenSatis = () => {
    setOpenSatis(true);
  };

  const handleCloseSatis = () => {
    setOpenSatis(false);
  };

  const handleOpenKVKK = () => {
    setOpenKVKK(true);
  };

  const handleCloseKVKK = () => {
    setOpenKVKK(false);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "90%", sm: "80%", md: "70%", lg: "60%" }, // Responsive width
    maxWidth: "1000px", // Sets a maximum width to ensure the modal does not become too wide
    maxHeight: "80vh",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  const handleFormSubmit = (event) => {
    if (!ssaccepted) {
      setAlertMessage(`Sınav kurallarını kabul etmek zorunludur`);
      setAlertOpen(true);
      return null;
    }

    if (!satisaccepted) {
      setAlertMessage(`Satış Sözleşmesi'ni kabul etmek zorunludur`);
      setAlertOpen(true);
      return null;
    }

    if (!kvkkaccepted) {
      setAlertMessage(`KVKK'yı kabul etmek zorunludur`);
      setAlertOpen(true);
      return null;
    }

    const requestObj = {
      isTcVatandasi: isTcVatandasi,
      tc: tc,
      dateofbirth: dateofbirth,
      name: name,
      surname: surname,
      email: email,
      phone: phone,
      address1: address1,
      address2: address2,
      city: city,
      province: province,
      zip: zip,
      country: country,
      placeofbirth: placeofbirth,
      gender: gender,
    };

    for (const key in requestObj) {
      const value = requestObj[key];
      if (key === "address2" || key === "province") {
        continue;
      }
      if (value === null || value === undefined || value === "") {
        setAlertMessage(`* ile işareli alanların doldurulması zorunludur.`);
        setAlertOpen(true);
        return null;
      }
    }

    return requestObj;
  };

  useImperativeHandle(ref, () => ({
    handleFormSubmit,
  }));

  const handlePlaceofbirthChange = (event) => {
    const selectedValue = event.target.value;
    setPlaceofbirth(selectedValue);
  };

  const handleGenderChange = (event) => {
    setGender(event.target.name);
  };

  const handleSSChange = (event) => {
    setSsAccepted(event.target.checked);
  };

  const handleKVKKChange = (event) => {
    setKvkkaccepted(event.target.checked);
  };

  const handleSatisChange = (event) => {
    setSatisaccepted(event.target.checked);
  };

  const handleCitizenshipChange = (event) => {
    setIsTcVatandasi(!event.target.checked);
  };

  const handleTcChange = (event) => {
    const selectedValue = event.target.value;
    setTc(selectedValue);
  };

  const handleDateOfBirthChange = (newTarih) => {
    const formattedDate = dayjs(newTarih).format("YYYY-MM-DD");
    //console.log(formattedDate); // Example: '2000-05-08'
    setDateOfBirth(formattedDate);
  };

  const handleNameChange = (event) => {
    const selectedValue = event.target.value;
    setName(selectedValue);
  };

  const handleSurnameChange = (event) => {
    const selectedValue = event.target.value;
    setSurname(selectedValue);
  };

  const handleEmailChange = (event) => {
    const selectedValue = event.target.value;
    setEmail(selectedValue);
  };

  const handlePhoneChange = (event) => {
    const selectedValue = event.target.value;
    setPhone(selectedValue);
  };

  const handleAddress1Change = (event) => {
    const selectedValue = event.target.value;
    setAddress1(selectedValue);
  };

  const handleAddress2Change = (event) => {
    const selectedValue = event.target.value;
    setAddress2(selectedValue);
  };

  const handleCityChange = (event) => {
    const selectedValue = event.target.value;
    setCity(selectedValue);
  };

  const handleProvinceChange = (event) => {
    const selectedValue = event.target.value;
    setProvince(selectedValue);
  };

  const handleZipChange = (event) => {
    const selectedValue = event.target.value;
    setZip(selectedValue);
  };

  const handleCountryChange = (event) => {
    const selectedValue = event.target.value;
    setCountry(selectedValue);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const currentLabel = isTcVatandasi ? labels[0] : labels[1];

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Kişisel Bilgiler
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="tcno"
            name="tcno"
            label={currentLabel}
            fullWidth
            //autoComplete="shipping address-line1"
            variant="standard"
            onChange={handleTcChange}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                name="notCitizen"
                checked={!isTcVatandasi} // Checkbox is checked when isTcVatandasi is false
                onChange={handleCitizenshipChange}
              />
            }
            label="TC vatandaşı değilim"
          />
        </Grid>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={gender === "bay"}
                    onChange={handleGenderChange}
                    name="bay"
                  />
                }
                label="Bay"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={gender === "bayan"}
                    onChange={handleGenderChange}
                    name="bayan"
                  />
                }
                label="Bayan"
              />
            </FormGroup>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="İsim"
            fullWidth
            autoComplete="given-name"
            variant="standard"
            onChange={handleNameChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Soyisim"
            fullWidth
            autoComplete="family-name"
            variant="standard"
            onChange={handleSurnameChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"tr"}>
            <DateField
              required
              id="dateofbirth"
              name="dateofbirth"
              label="Doğum Tarihi"
              fullWidth
              autoComplete="bday"
              variant="standard"
              onChange={(newTarih) => handleDateOfBirthChange(newTarih)}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="placeofbirth"
            name="placeofbirth"
            label="Doğum yeri"
            fullWidth
            variant="standard"
            onChange={handlePlaceofbirthChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="email"
            name="email"
            label="Email"
            fullWidth
            autoComplete="shipping email"
            variant="standard"
            onChange={handleEmailChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="phone"
            name="phone"
            label="Telefon No"
            fullWidth
            autoComplete="shipping tel"
            variant="standard"
            onChange={handlePhoneChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="address1"
            name="address1"
            label="Adres 1"
            fullWidth
            autoComplete="shipping address-line1"
            variant="standard"
            onChange={handleAddress1Change}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="address2"
            name="address2"
            label="Adres 2"
            fullWidth
            autoComplete="shipping address-line2"
            variant="standard"
            onChange={handleAddress2Change}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="city"
            name="city"
            label="Şehir"
            fullWidth
            autoComplete="shipping address-level2"
            variant="standard"
            onChange={handleCityChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="state"
            name="state"
            label="Bölge/Eyalet"
            fullWidth
            variant="standard"
            onChange={handleProvinceChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="zip"
            name="zip"
            label="Zip / Posta kodu"
            fullWidth
            autoComplete="shipping postal-code"
            variant="standard"
            onChange={handleZipChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="country"
            name="country"
            label="Ülke"
            fullWidth
            autoComplete="shipping country"
            variant="standard"
            onChange={handleCountryChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                name="sskabul"
                checked={ssaccepted}
                onChange={handleSSChange}
              />
            }
            label={
              <span>
                <Link
                  component="button"
                  onClick={handleOpenSS}
                  sx={{
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Sınav Kuralları
                </Link>
                'nı okudum ve kabul ediyorum.
              </span>
            }
          />
          <Modal
            open={openSS}
            onClose={handleCloseSS}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalStyle}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Sınav Kuralları
              </Typography>
              <Typography variant="h6" gutterBottom>
                TELC Sınava Giriş Kuralları
              </Typography>
              <Typography variant="body1" gutterBottom>
                Telc sınavlarına giriş kuralları, sınavın düzenleyen kurumun
                politikalarına ve sınav merkezinin belirlediği prosedürlere göre
                değişebilir. Ancak genel olarak Telc sınavlarına giriş kuralları
                aşağıda belirtilmiştir.
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                Kimlik Belgesi:
              </Typography>
              <Typography variant="body2">
                Sınav gününde kimliğinizi yanınıza almanız gerekebilir.
                Genellikle pasaport, nüfus cüzdanı veya ehliyet gibi resmi
                kimlik belgeleri kabul edilir. Kimlik belgenizin geçerli olması
                ve üzerinde fotoğrafınızın ve imzanızın bulunması önemlidir.
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                Sınav Kayıt ve Giriş Kartı:
              </Typography>
              <Typography variant="body2">
                Sınav merkezi, size sınavın tarihini ve yeri ile ilgili
                bilgileri içeren bir sınav kayıt veya giriş kartı sağlayacaktır.
                Bu kartı sınav gününde yanınıza almalısınız ve sınav
                görevlilerine ibraz etmelisiniz.
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                Giriş Saati:
              </Typography>
              <Typography variant="body2">
                Sınavın başlama saati önemlidir. Genellikle sınav merkezi
                belirli bir süre önce sınav yerine gelmenizi ister. Belirtilen
                saatte gelmeye dikkat edin, çünkü geç kalmak sınav hakkınızı
                kaybetmenize neden olabilir.
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                Ekipman ve Malzemeler:
              </Typography>
              <Typography variant="body2">
                Sınavda kullanmanız gereken özel ekipmanlar veya malzemeler
                varsa, bunları yanınıza almalısınız. Sınav kurallarına uygun
                malzemeler kullanmalısınız.
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                Saat ve Cep Telefonları:
              </Typography>
              <Typography variant="body2">
                Sınav sırasında saat kullanmak kesinlikle yasaktır. Ayrıca cep
                telefonları ve diğer elektronik cihazlar sınavdan önce emanet
                görevlisi tarafından alınarak kilitli dolaplarda sınav çıkışına
                kadar saklanır. Bu cihazları sınav odasına getirmemeye özen
                göstermelisiniz. Aksi taktirde sınavınızın iptal edilmesini
                gerçekleştirmiş olursunuz.
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                Sınav Görevlilerinin Talimatlarına Uygun Davranış:
              </Typography>
              <Typography variant="body2">
                Sınav sırasında sınav görevlilerinin talimatlarına uymalısınız.
                Hangi sırayla oturmanız gerektiğinden, sınavın nasıl
                tamamlanacağına kadar tüm kurallara uymalısınız.
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                Hile Yapmamak:
              </Typography>
              <Typography variant="body2">
                Sınavda hile yapmak, kopya çekmek veya başka hileli yöntemler
                kullanmak kesinlikle yasaktır ve ciddi sonuçlara yol açabilir.
                Sınavın türüne ve sınav merkezinin politikalarına bağlı olarak
                bu kurallar değişebilir, bu nedenle sınav kayıt işlemleri
                sırasında ve sınav gününde size verilen talimatları dikkatle
                takip etmelisiniz. Bu kurallara uygun davranarak sınavı
                başarıyla tamamlayabilirsiniz.
              </Typography>
            </Box>
          </Modal>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                name="satiskabul"
                checked={satisaccepted}
                onChange={handleSatisChange}
              />
            }
            label={
              <span>
                <Link
                  component="button"
                  onClick={handleOpenSatis}
                  sx={{
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Satış Sözleşmesi
                </Link>
                'ni okudum ve kabul ediyorum.
              </span>
            }
          />
          <Modal
            open={openSatis}
            onClose={handleCloseSatis}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalStyle}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Mesafeli Satış Sözleşmesi
              </Typography>
              <Typography variant="body1" gutterBottom>
                İş bu sözleşme 13.06.2003 tarih ve 25137 sayılı Resmi Gazetede
                yayınlanan Mesafeli Sözleşmeler Uygulama Usul ve Esasları
                Hakkında Yönetmelik gereği internet üzerinden gerçekleştirilen
                satışlar için sözleşme yapılması zorunluluğuna istinaden
                düzenlenmiştir.
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                MADDE 1 - KONU
              </Typography>
              <Typography variant="body2">
                İş bu sözleşmenin konusu, SATICI’nın, ALICI’ya satışını yaptığı,
                ilgili web sitesinde nitelikleri ve satış fiyatı belirtilen
                ürünün satışı ve teslimi ile ilgili olarak 4077 sayılı
                Tüketicilerin Korunması Hakkındaki Kanun-Mesafeli Sözleşmeleri
                Uygulama Esas ve Usulleri Hakkında Yönetmelik hükümleri
                gereğince tarafların hak ve yükümlülüklerini kapsamaktadır.
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                MADDE 2 – SATICI BİLGİLERİ
              </Typography>
              <Typography variant="body2">
                Ünvan: BERLİNER GRUP EĞİTİM DANIŞMANLIK YAYINCILIK TURİZM SANAYİ
                VE TİCARET LİMİTED ŞİRKETİ
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                MADDE 3 – ALICI BİLGİLERİ
              </Typography>
              <Typography variant="body2">
                Müşteri olarak www.berliner.com.tr alışveriş sitesine üye olan
                kişi. Üye olurken kullanılan adres ve iletişim bilgileri esas
                alınır. Bundan böyle (ALICI) olarak anılacaktır.
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                MADDE 4 – SÖZLEŞME KONUSU ÜRÜN BİLGİLERİ
              </Typography>
              <Typography variant="body2">
                Malın/Ürünün/Hizmetin türü, miktarı, marka/modeli, rengi, adedi,
                satış bedeli, ödeme şekli, siparişin sonlandığı andaki
                bilgilerden oluşmaktadır. Detayları siparişin verildiği zamanda
                www.berliner.com.tr web sitesinde belirtilmektedir. Ayrıca
                sipariş sonrası ALICI’nın üye olurken belirttiği mail adresine
                talebi dahilinde gönderilmektedir. Fiyat dahil tüm vaatlerimizin
                geçerlilik süresi siparişin oluşturulduğu zaman (gün, saat ve
                dakika) itibariyle geçerlidir. SATICI eğitim tarihleri, eğitmen,
                eğitim yeri, eğitim süresi ve içerik ile ilgili her türlü
                değişiklik yapma hakkını saklı tutar. Belirtilen ücretlere KDV
                dahildir.
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                MADDE 5 – GENEL HÜKÜMLER
              </Typography>
              <Typography variant="body2">
                5.1 – ALICI, Madde 3’te belirtilen sözleşme konusu ürün veya
                ürünlerin temel nitelikleri, satış fiyatı ve ödeme şekli ile
                teslimata ilişkin tüm ön bilgileri okuyup bilgi sahibi olduğunu
                ve elektronik ortamda gerekli teyidi verdiğini beyan eder. 5.2 –
                Sözleşme konusu ürün veya ürünler, yasal 30 günlük süreyi
                aşmamak koşulu ile her bir ürün için ALICI’nın yerleşim yerinin
                uzaklığına bağlı olarak ön bilgiler içinde açıklanan süre içinde
                ALICI veya gösterdiği adresteki kişi/kuruluşa teslim edilir. Bu
                süre ALICI’ya daha önce bildirilmek kaydıyla en fazla 10 gün
                daha uzatılabilir. 5.3 – Sözleşme konusu ürün, ALICI’dan başka
                bir kişi/kuruluşa teslim edilecek ise, teslim edilecek
                kişi/kuruluşun teslimatı kabul etmemesinden SATICI sorumlu
                tutulamaz. 5.4 – SATICI, sözleşme konusu ürünün sağlam,
                eksiksiz, siparişte belirtilen niteliklere uygun ve varsa
                garanti belgeleri ve kullanım kılavuzları ile teslim
                edilmesinden sorumludur. 5.5 – Sözleşme konusu ürünün teslimatı
                için iş bu sözleşmenin imzalı nüshasının SATICI iletişim
                adresine ulaştırılmış olması ve bedelinin ALICI’nın tercih
                ettiği ödeme şekli ile ödenmiş olması şarttır. Herhangi bir
                nedenle ürün bedeli ödenmez veya banka kayıtlarında iptal edilir
                ise, SATICI ürünün teslimi yükümlülüğünden kurtulmuş kabul
                edilir. 5.6 - Ürünün tesliminden sonra ALICI’ya ait kredi
                kartının ALICI’nın kusurundan kaynaklanmayan bir şekilde
                yetkisiz kişilerce haksız veya hukuka aykırı olarak kullanılması
                nedeni ile ilgili banka veya finans kuruluşun ürün bedelini
                SATICI namına ödememesi halinde, eğer ürünler iade edilebilir
                ürün statüsünde ise (Bu ürünler İptal & İade Koşullarında ayrıca
                belirtilmektedir.) ALICI’nın kendisine teslim edilmiş olması
                kaydıyla ürünün 3 gün içinde SATICI iletişim adres(ler)ine
                gönderilmesi zorunludur. Bu takdirde nakliye giderleri ALICI’ya
                aittir. Ancak cayma hakkının kullanılamayacağı eğitim ve öğretim
                faaliyetlerinde (Bu ürünler İptal & İade Koşullarında ayrıca
                belirtilmektedir.) belirtilen ürün veya hizmet satın alması
                yapılmışsa, ALICI bu bedelin geri ödenmesini talep etmeyeceğini
                peşinen kabul etmiş sayılır. ALICI alınan mal veya hizmet
                bedelinin SATICI tarafından, yukarıda belirtilen bilgiler
                doğrultusunda, alacak olarak kaydedilmesini beyan eder. ALICI
                Kart numarasının değişmesi, son kullanma tarihinin geçmesi,
                çalınması, kaybolması durumunda, gerekli yerlere yasal başvuru
                yapacağını, SATICI’nın bu durumla ilişkilendirilmeyeceğini kabul
                eder. Ayrıca ALICI önceden vermiş olduğu ödeme
                tarihinde,ödemenin iptalinin istenmesi veya ödenmemesi gibi
                isteklerinden feragat ettiğini, sipariş vermesi suretiyle kabul
                ve taahhüt eder. SATICI gerek görülmesi durumunda ALICI’ya
                ödemeyi internetten yaptığına ve iade etmeyeceğine dair Ödeme
                Formu doldurma ve onay isteme hakkını saklı tutar. 5.7- SATICI
                mücbir sebepler veya nakliyeyi engelleyen hava muhalefeti,
                ulaşımın kesilmesi gibi olağanüstü durumlar nedeni ile sözleşme
                konusu ürünü süresi içinde teslim edemez ise,durumuALICI’ya
                bildirmekle yükümlüdür. Bu takdirde ALICI siparişin iptal
                edilmesini, sözleşme konusu ürünün varsa emsali ile
                değiştirilmesini,ve/veya teslimat süresinin engelleyici durumun
                ortadan kalkmasına kadar ertelenmesi haklarından birini
                kullanabilir. ALICI’nın satın aldığı ve İptal & İade
                Koşullarında Cayma hakkının kullanılamayacağı durumlarda
                belirtilen ürünler hariç olmak kaydıyla, siparişi iptal etmesi
                halinde ödediği tutar 30 gün içinde kendisine nakten ve defaten
                ödenir. Eğer kredi kartıyla ödeme yapılmışsa aynı kredi kartına
                iadesi gerçekleştirilir. Fatura aslı gönderilmez ise KDV ve
                diğer vergiler sair yasal yükümlülükler iade edilmez. 5.8-
                Garanti belgesi ile satılan ürünlerden olan veya olmayan
                ürünlerin arızalı veya bozuk olanlar,garanti şartları içinde
                gerekli onarımın yapılması için SATICI iletişim adreslerine
                gönderilebilir, bu takdirde kargo giderleri ALICI tarafından
                karşılanacaktır.
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                MADDE 6 – ALICININ YÜKÜMLÜLÜKLERİ
              </Typography>
              <Typography variant="body2">
                6.1- ALICI, Eğitim içeriğinde öğrenmiş olduğu bilgileri ve
                teknikleri kesinlikle diğer insanları manipüle edici ya da zarar
                verecek şekilde kullanmayacaktır. Bu maddenin ihlali söz konusu
                olduğunda Katılım Belgesi, Eğitim Belgesi(program dahilinde ise)
                iptal edilecektir ve derhal SATICI’ya iade edecektir. 6.2-
                ALICI, satın aldığı eğitimin içeriğinin terapi amaçlı bir eğitim
                olmadığını; kişisel gelişim, danışmanlık ve eğitim desteği
                amaçlı bir program olduğunu kabul eder. 6.3- ALICI,
                yararlanacağı eğitime başlarken fiziksel ve zihinsel sağlığının
                ve psikolojisinin iyi olduğunu kabul eder. Bu konuda en ufak bir
                şüphe durumunda bir (doktor, psikiyatrist vb.) profesyonelin
                görüşünü alması gerektiğinin bilincinde olduğu ve herhangi bir
                şekilde doktor ya da psikiyatrist tarafından eğitime
                katılmasında sakınca görülmediğini kabul eder. 6.4- ALICI,
                SATICI’nın eğitim ile ilgili gerekli koşullar sağlandığında
                garanti verdiğini kabul eder. 6.4-1- SATICI alıcının eğer
                sınavdan yeterli puan alamadığı taktirde belge ile ispat ederek
                ücretsiz eğitim almasını garanti eder. 6.5.- ALICI,
                www.berliner.com.tr markasının ve eğitimlerinin tüm mali ve
                manevi haklarının SATICI’ya ait olduğunu kabul eder. ALICI, tüm
                hakları SATICI’ya aittir. 6.6- ALICI prosedürü tamamlaması
                halinde SATICI’dan alacağı sertifika ve/veya diplomalar ile
                devletin kanunlarla tıp okumuş kişilere verdiği yetkinlikte
                belirlediği tedavi amaçlı terapi ve hipnoterapi yapamayacağını
                kabul eder. 6.7- ALICI, eğitim ve hizmet aşamalarını tamamlaması
                halinde verilen sertifikanın Milli Eğitim Bakanlığı ile hiçbir
                bağlantısı olmadığını kabul eder. 6.8- ALICI, SATICI’dan aldığı
                eğitim programının içinde verilecek olan eğitim materyallerini
                (CD-DVD-manuel-uygulama kitapçığı,eğitim videoları) hiçbir
                şekilde kopyalayamaz, çoğaltamaz, satamaz. ALICI herhangi bir
                şekilde söz konusu materyalleri kopyaladığı takdirde bu
                ürünlerle ilgili SATICI’nın uğrayacağı tüm maddi ve manevi
                zararları ile yoksun kalınan kazancı tazmin edeceği gibi cezai
                yaptırımlarla da karşı karşıya kalacaktır. 6.9- ALICI, senet
                veya taksitle ödeme yapıyor ise taksitlerin zamanında
                ödeyeceğini kabul eder. Eğer taksidinin bir tanesini bile
                aksatırsa kalan diğer taksit tutarlarının muaccel hale
                geleceğini kabul eder. 6.10- ALICI, SATICI’ya ödeme karşılığında
                üye olduğu takdirde bu üyeliğin 1 yıl sürdüğünü ve yıllık üyelik
                aidatını ödüyor olması durumunda üyelik hakkının devam edeceğini
                kabul eder. 6.11- ALICI, SATICI’dan aldığı eğitim programı ile
                ilgili haklarını 1.derece akrabaları dahil kimseye
                deviremeyeceğini kabul eder. 6.12- ALICI, satın aldığı seminer
                ve hizmet setinin içeriği ve mahiyeti hakkında detaylı olarak
                bilgilendirildiğini ve satın aldığı ürün hakkında her türlü
                açıklamanın kendisine yapıldığını kabul ve beyan eder.
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                MADDE 7 – DİĞER HUSUSLAR
              </Typography>
              <Typography variant="body2">
                7.1 – TEMERRÜD ALICI’nın temerrüde düşmesi halinde, ALICI borcun
                gecikmeli ifasından dolayı SATICI’nın oluşan zarar ve ziyanını
                ödemeyi kabul eder. Ödemede herhangi bir gecikme durumunda aylık
                döviz bazında %5, TL bazında %10 faiz uygulanacaktır. 7.2 –
                YETKİLİ MERCİLER İş bu sözleşmenin uygulanmasında, Sanayi ve
                Ticaret Bakanlığınca ilan edilen değere kadar Tüketici Hakem
                Heyetleri ile ANKARA Tüketici Mahkemeleri yetkilidir. 7.3 –
                YÜRÜTME Bu yönetmelik hükümlerini Sanayi ve Ticaret Bakanlığı
                yürütür. 7.4 – GEÇERLİLİK Bu sözleşme siparişin gerçekleştiği
                tarihte ALICI’ya bildirilmiş olup, İptal & iade Koşulları bu
                sözleşmenin bir eki olmak üzere sözleşme 7 ana maddeden ve 28
                alt maddeden oluşmaktadır. Siparişin gerçekleşmesi durumunda
                ALICI iş bu sözleşmenin tüm koşullarını kabul etmiş sayılır.
              </Typography>
            </Box>
          </Modal>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                name="kvkkkabul"
                checked={kvkkaccepted}
                onChange={handleKVKKChange}
              />
            }
            label={
              <span>
                <Link
                  component="button"
                  onClick={handleOpenKVKK}
                  sx={{
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Kişisel Verilerin Korunması ve İşlenmesi Politikası
                </Link>
                'nı okudum ve kabul ediyorum.
              </span>
            }
          />
          <Modal
            open={openKVKK}
            onClose={handleCloseKVKK}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalStyle}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
              ></Typography>
              <Typography variant="h5" gutterBottom>
                BERLİNER GRUP EĞİTİM DANIŞMANLIK YAYINCILIK TURİZM SANAYİ VE
                TİCARET LİMİTED ŞİRKETİ KİŞİSEL VERİLERİN KORUNMASI VE İŞLENMESİ
                POLİTİKASI
              </Typography>
              <Typography variant="h6" gutterBottom>
                1- GİRİŞ
              </Typography>
              <Typography paragraph>
                BERLİNER GRUP EĞİTİM DANIŞMANLIK YAYINCILIK TURİZM SANAYİ VE
                TİCARET LİMİTED ŞİRKETİ yürüttüğü faaliyetlerde kişisel
                verilerin korunmasına önem vermektedir. Kişisel Verilerin
                Korunması ve işlenmesi Politikası (“Politika”), 6698 sayılı
                Kişisel Verilerin Korunması Kanunu’nca (“Kanun”) belirlenen
                kişisel veri işleme usul ve esaslarının BERLİNER GRUP EĞİTİM
                DANIŞMANLIK YAYINCILIK TURİZM SANAYİ VE TİCARET LİMİTED ŞİRKETİ
                bu Politika prensipleri doğrultusunda, üst düzey sorumluluk ve
                bilinciyle kişisel verileri işlemekte ve korumakta, kişisel veri
                sahiplerini bilgilendirerek gerekli şeffaflığı temin etmektedir.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                1.1 Amaç
              </Typography>
              <Typography paragraph>
                Bu Politikanın amacı, Kanun ve ilgili diğer mevzuat ile
                öngörülen usul ve esasları, BERLİNER GRUP EĞİTİM DANIŞMANLIK
                YAYINCILIK TURİZM SANAYİ VE TİCARET LİMİTED ŞİRKETİ organizasyon
                ve süreçlerine uyumlulaştırarak, faaliyetlerinde etkin bir
                şekilde uygulanmasını sağlamaktır. Kişisel verilerin işlenmesi
                ve korunması için bu Politika ile her türlü idari ve teknik
                önlemleri almakta, gerekli iç prosedürler oluşturmakta,
                farkındalığı arttırmakta, bilincin sağlanması için gerekli tüm
                eğitimleri yapmaktadır. Hissedarlar, yetkililer, çalışanlar ve
                iş ortaklarının Kanun süreçlerine uyumları için, gerekli tüm
                önlemler alınmakta, uygun ve etkin denetim mekanizmaları
                kurulmaktadır.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                1.2 Kapsam
              </Typography>
              <Typography paragraph>
                Politika, BERLİNER GRUP EĞİTİM DANIŞMANLIK YAYINCILIK TURİZM
                SANAYİ VE TİCARET LİMİTED ŞİRKETİ iş süreçlerinde otomatik olan
                ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla
                otomatik olmayan yollarla elde edilen bütün kişisel verileri
                kapsamaktadır.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                1.3 Dayanak
              </Typography>
              <Typography paragraph>
                Bu politika, kanun ve ilgili mevzuata dayanmaktadır. Kişisel
                veriler, 6502 sayılı Tüketicinin Korunması Hakkında Kanun, 1774
                sayılı Kimlik Bildirme Kanunu, 4857 sayılı İş Kanunu, 6331 İş
                Sağlığı ve Güvenliği Kanunu, 5510 sayılı Sosyal Sigortalar ve
                Genel Sağlık Sigortası Kanunu, 4447 sayılı İşsizlik Sigortası
                Kanunu, 6102 sayılı Türk Ticaret Kanunu, 213 sayılı Vergi Usul
                Kânunu ve diğer ilgili mevzuattan ve diğer ilgili mevzuattan
                kaynaklanan yasal yükümlülükleri yerine getirmek için
                işlenmektedir. Yürürlükteki mevzuat ve Politika arasında
                uyumsuzluk olduğu hallerde yürürlükteki mevzuat uygulanır.
                İlgili mevzuat tarafından öngörülen düzenlemeler, Politika ile
                BERLİNER GRUP EĞİTİM DANIŞMANLIK YAYINCILIK TURİZM SANAYİ VE
                TİCARET LİMİTED ŞİRKETİ uygulamalarına dönüştürülmektedir.
              </Typography>
              <Typography variant="h6" gutterBottom>
                1.4 Tanımlar
              </Typography>
              <Typography paragraph>
                <strong>Açık Rıza:</strong> Belirli bir konuya ilişkin,
                bilgilendirilmeye dayanan ve özgür iradeyle açıklanan rızayı
                ifade eder.
              </Typography>
              <Typography paragraph>
                <strong>Başvuru Formu:</strong> Kişisel veri sahiplerinin
                haklarını kullanmak için yapacakları başvuruyu içeren, 6698
                sayılı Kişisel Verilerin Korunması Kanununa ve Kişisel Verileri
                Koruma Kurumunun çıkardığı Veri Sorumlusuna Başvuru Usul ve
                Esasları Hakkında Tebliğe uygun olarak hazırlanmış, Kişisel Veri
                Sahibi tarafından veri sorumlusuna yapılacak başvurulara ilişkin
                başvuru formu.
              </Typography>
              <Typography paragraph>
                <strong>İlgili Kullanıcı:</strong> Verilerin teknik olarak
                depolanması, korunması ve yedeklenmesinden sorumlu olan kişi ya
                da birim hariç olmak üzere veri sorumlusu organizasyonu
                içerisinde veya veri sorumlusundan aldığı yetki ve talimat
                doğrultusunda kişisel verileri işleyen kişilerdir.
              </Typography>
              <Typography paragraph>
                <strong>İmha:</strong> Kişisel verilerin silinmesi, yok edilmesi
                veya anonim hale getirilmesi.
              </Typography>
              <Typography paragraph>
                <strong>Kayıt Ortamı:</strong> Tamamen veya kısmen otomatik olan
                ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla
                otomatik olmayan yollarla işlenen kişisel verilerin bulunduğu
                her türlü ortam.
              </Typography>
              <Typography paragraph>
                <strong>Kişisel Veri:</strong> Kimliği belirli veya
                belirlenebilir gerçek kişiye ilişkin her türlü bilgi.
              </Typography>
              <Typography paragraph>
                <strong>Kişisel Verilerin İşlenmesi:</strong> Kişisel verilerin
                tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt
                sisteminin parçası olmak kaydıyla otomatik olmayan yollarla elde
                edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi,
                değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması,
                devralınması, elde edilebilir hâle getirilmesi,
                sınıflandırılması ya da kullanılmasının engellenmesi gibi
                veriler üzerinde gerçekleştirilen her türlü işlem.
              </Typography>
              <Typography paragraph>
                <strong>Kişisel Verilerin Anonim Hale Getirilmesi:</strong>{" "}
                Kişisel verilerin, başka verilerle eşleştirilerek dahi hiçbir
                surette kimliği belirli veya belirlenebilir bir gerçek kişiyle
                ilişkilendirilemeyecek hâle getirilmesi.
              </Typography>
              <Typography paragraph>
                <strong>Kişisel Veri Sahibi:</strong> Kişisel verileri BERLİNER
                GRUP EĞİTİM DANIŞMANLIK YAYINCILIK TURİZM SANAYİ VE TİCARET
                LİMİTED ŞİRKETİ tarafından veya adına işleme sokulan gerçek
                kişi.
              </Typography>
              <Typography paragraph>
                <strong>Kişisel Verilerin Silinmesi:</strong> Kişisel verilerin
                silinmesi; kişisel verilerin İlgili Kullanıcılar için hiçbir
                şekilde erişilemez ve tekrar kullanılamaz hale getirilmesi.
              </Typography>
              <Typography paragraph>
                <strong>Kişisel Verilerin Yok Edilmesi:</strong> Kişisel
                verilerin hiç kimse tarafından hiçbir şekilde erişilemez, geri
                getirilemez ve tekrar kullanılamaz hale getirilmesi işlemi.
              </Typography>
              <Typography paragraph>
                <strong>Kurul:</strong> Kişisel Verileri Koruma Kurulu.
              </Typography>
              <Typography paragraph>
                <strong>Kurum:</strong> Kişisel Verileri Koruma Kurumu.
              </Typography>
              <Typography paragraph>
                <strong>Özel Nitelikli Kişisel Veri:</strong> Kişilerin ırkı,
                etnik kökeni, siyasi düşüncesi, felsefî inancı, dini, mezhebi
                veya diğer inançları, kılık ve kıyafeti, demek, vakıf ya da
                sendika üyeliği, sağlığı, cinsel hayatı, ceza mahkûmiyeti ve
                güvenlik tedbirleriyle ilgili verileri ile biometrik ve genetik
                verileri.
              </Typography>
              <Typography paragraph>
                <strong>Periyodik İmha:</strong> Kanun’da yer alan kişisel
                verilerin işlenme şartlarının tamamının ortadan kalkması
                durumunda kişisel verileri saklama ve imha politikasında
                belirtilen ve tekrar eden aralıklarla re sen gerçekleştirilecek
                silme, yok etme veya anonim hale getirme işlemi.
              </Typography>
              <Typography paragraph>
                <strong>Veri İşleyen:</strong> Veri sorumlusunun verdiği yetkiye
                dayanarak onun adına kişisel veri işleyen gerçek ve tüzel kişi.
              </Typography>
              <Typography paragraph>
                <strong>Veri Kayıt Sistemi:</strong> Kişisel verilerin belirli
                kıstaslara göre yapılandırılarak işlendiği kayıt sistemi.
              </Typography>
              <Typography paragraph>
                <strong>Veri Sahibi / İlgili Kişi:</strong> Kişisel verisi
                işlenen gerçek kişi.
              </Typography>
              <Typography paragraph>
                <strong>Veri Sorumlusu:</strong> Kişisel verilerin işleme
                amaçlarını ve vasıtalarını belirleyen, veri kayıt sisteminin
                kurulmasından ve yönetilmesinden sorumlu olan gerçek veya tüzel
                kişi.
              </Typography>
              <Typography paragraph>
                <strong>Veri Temsilcisi:</strong> Kanun uyarınca Veri
                Sorumlusunun ilgili kanun maddeleri kapsamındaki görevlerini
                yerine getirmek üzere atanmış gerçek kişidir.
              </Typography>
              <Typography paragraph>
                <strong>Yönetmelik:</strong> 28 Ekim 2017 tarihinde Resmi Gazete
                de yayımlanan Kişisel Verilerin Silinmesi, Yok Edilmesi veya
                Anonim Hale Getirilmesi Hakkında Yönetmelik.
              </Typography>
              <Typography variant="h6" gutterBottom>
                2- KİŞİSEL VERİLERİN İŞLENMESİ
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                2.1 Kişisel Verilerin İşlenme Prensipleri
              </Typography>
              <Typography paragraph>
                BERLİNER GRUP EĞİTİM DANIŞMANLIK YAYINCILIK TURİZM SANAYİ VE
                TİCARET LİMİTED ŞİRKETİ kişisel verileri, Kanun’da ve ilgili
                diğer mevzuatlarda öngörülen usul ve esaslara uygun olarak
                işlenmektedir. Bu doğrultuda kişisel verileri:
              </Typography>
              <Typography paragraph>
                <strong>A)</strong> Hukuka ve dürüstlük kurallarına uygun,
                <br />
                <strong>B)</strong> Doğru ve gerektiğinde güncel,
                <br />
                <strong>C)</strong> Belirli, açık ve meşru amaçlar için,
                <br />
                <strong>D)</strong> İşlendikleri amaçla bağlantılı, sınırlı ve
                ölçülü olarak,
                <br />
                <strong>E)</strong> İlgili mevzuatta öngörülen veya işlendikleri
                amaç için gerekli olan süre kadar işlemektedir.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                2.2 Verilerin İşlenmesi
              </Typography>
              <Typography paragraph>
                Kişisel veri sahibinin açık rıza vermesi, kişisel verilerin
                hukuka uygun olarak işlenmesini mümkün kılan hukuki
                dayanaklardan yalnızca bir tanesi olup, aşağıda yer alan
                şartlardan birinin varlığı durumunda kişisel veriler, veri
                sahibinin açık rızası aranmaksızın işlenmektedir:
              </Typography>
              <Typography paragraph>
                <strong>A)</strong> Kanunlarda Açıkça Öngörülmesi: Kanunlarda
                açıkça öngörülen hallerde kişisel veriler işlenebilecektir.
                BERLİNER GRUP bu durumda, ilgili hukuki düzenlemeler
                çerçevesinde kişisel verileri işlemektedir.
                <br />
                <strong>B)</strong> Fiili İmkansızlık Sebebiyle İlgilinin Açık
                Rızasının Alınamaması: Fiili imkansızlık nedeniyle rızasını
                açıklayamayacak durumda olan veya rızasına geçerlilik
                tanınamayacak olan kişinin kendisinin ya da başka bir kişinin
                hayatı veya beden bütünlüğünü korumak için kişisel verisinin
                işlenmesinin zorunlu olması halinde veri sahibinin kişisel
                verileri işlenebilecektir.
                <br />
                <strong>C)</strong> Sözleşmenin Kurulması veya İfasıyla Doğrudan
                İlgili Olması: Bir sözleşmenin kurulması veya ifasıyla doğrudan
                doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait
                kişisel verilerin işlenmesinin gerekli olması halinde kişisel
                verilerin işlenmesi mümkündür.
                <br />
                <strong>D)</strong> Hukuki Yükümlülüğün Yerine Getirilmesi:
                BERLİNER GRUP veri sorumlusu olarak hukuki yükümlülüklerini
                yerine getirmesi için işlemenin zorunlu olması halinde veri
                sahibinin kişisel verileri işlenebilecektir.
                <br />
                <strong>E)</strong> Kişisel Veri Sahibinin Kişisel Verisini
                Alenileştirmesi: Veri sahibi tarafından herhangi bir şekilde
                kamuoyuna açıklanmış olan ve alenileştirilme sonucu herkesin
                bilgisine açılmış olan kişisel veriler alenileştirme amacı ile
                sınırlı olarak işlenebilecektir.
                <br />
                <strong>F)</strong> Bir Hakkın Tesisi veya Korunması için Veri
                İşlemenin Zorunlu Olması: Bir hakkın tesisi, kullanılması veya
                korunması için veri işlemenin zorunlu olması halinde veri
                sahibinin kişisel verileri işlenebilecektir.
                <br />
                <strong>G)</strong> BERLİNER GRUP EĞİTİM DANIŞMANLIK YAYINCILIK
                TURİZM SANAYİ VE TİCARET LİMİTED ŞİRKETİ’nin Meşru Menfaati için
                Veri İşlemenin Zorunlu Olması: BERLİNER GRUP ile veri sahibinin
                menfaat dengesinin gözetilmesi şartıyla kişisel veriler
                işlenebilecektir. Bu kapsamda, meşru menfaate dayanarak
                verilerin işlenmesinde öncelikle işleme faaliyeti sonucunda elde
                edeceği meşru menfaati belirler ve kişisel verilerin
                işlenmesinin veri sahibinin hak ve özgürlükleri üzerindeki olası
                etkisini değerlendirir ve dengenin bozulmadığı kanaatindeyse
                işleme faaliyetini gerçekleştirir.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                2.2.2 Özel Nitelikli Kişisel Verilerin İşlenmesi
              </Typography>
              <Typography paragraph>
                Kişisel verilerin bir kısmı, ‘özel nitelikli kişisel veriler’
                olarak ayrı şekilde düzenlenmekte ve özel bir korumaya tabi
                olmaktadır. Hukuka aykırı olarak işlendiğinde kişilerin
                mağduriyetine sebep olma veya ayrımcılığa maruz kalma riski
                nedeniyle, bu verilere özel önem atfedilmiştir. BERLİNER GRUP
                EĞİTİM DANIŞMANLIK YAYINCILIK TURİZM SANAYİ VE TİCARET LİMİTED
                ŞİRKETİ özel nitelikli kişisel verileri, Kanun ve Politikada
                belirlenen ilkelere uygun, Kurul’un belirlediği yöntemlerle
                gerekli her türlü idari ve teknik önlemleri alarak, kanunlarda
                işlenmesine ilişkin açıkça bir hüküm olması durumunda veri
                sahibinin açık rızası aranmadan işlenebilir. Kanunlarda açıkça
                öngörülmeyen hallerde veri sahibinin açık rızası alınır.
                Sözleşmelerden doğan haklar gerekçesiyle de kişisel veriler
                işlenebilecektir.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                2.3 Kişisel Verilerin İşlenme Amaçları
              </Typography>
              <Typography paragraph>
                Kanun ve ilgili diğer mevzuatlara uygun şekilde işbu Politika’da
                detaylandırılan kişisel veriler ve özel nitelikli kişisel
                verilerin işlenme şartları kapsamında BERLİNER GRUP EĞİTİM
                DANIŞMANLIK YAYINCILIK TURİZM SANAYİ VE TİCARET LİMİTED ŞİRKETİ
                kişisel veri işleme amaçları aşağıdaki gibidir:
              </Typography>
              <Typography paragraph>
                <strong>A)</strong> İnsan kaynakları politikalarının ve
                süreçlerinin planlanması ve/veya icra edilmesi,
                <br />
                <strong>B)</strong> İş ilişkisi içerisinde olan ilgili kişilerin
                fiziki, hukuki ve teknik güvenliğinin temini faaliyetlerinin
                planlanması ve/veya icrası,
                <br />
                <strong>C)</strong> İlgili kişileri faydalandırmak için gerekli
                çalışmaların yapılması ve ilgili iş süreçlerinin yürütülmesi,
                <br />
                <strong>D)</strong> Ticari ve/veya operasyonel faaliyetlerin
                gerçekleştirilmesi için gerekli çalışmaların yapılması ve buna
                bağlı iş süreçlerinin yürütülmesi,
                <br />
                <strong>E)</strong> Ticari ve/veya iş stratejilerinin
                planlanması ve/veya icrası.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                3- KİŞİSEL VERİLERİN AKTARILMASI
              </Typography>
              <Typography paragraph>
                BERLİNER GRUP EĞİTİM DANIŞMANLIK YAYINCILIK TURİZM SANAYİ VE
                TİCARET LİMİTED ŞİRKETİ, kişisel veri işleme amaçlan
                doğrultusunda, gerekli güvenlik önlemlerini alarak, kişisel
                verileri ve özel nitelikli kişisel verileri (Tedarikçilere ve
                Yetkili Kamu Kurum ve Kuruluşlarına) hukuka uygun olarak
                aktarabilir. Kişisel veri aktarma işlemleri, Kanun’un 8.
                maddesinde öngörülen düzenlemelere uygun şekilde
                gerçekleştirilir.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                3.1 Kişisel Verilerin Aktarılması
              </Typography>
              <Typography paragraph>
                Veri sahibinin açık rızasının bulunması durumunda ve aşağıda
                sayılı şartların varlığı halinde veri sahibinin açık rızası
                aranmaksızın gerekli özen gösterilerek ve Kurul tarafından
                öngörülen yöntemler de dahil gerekli tüm güvenlik önlemleri
                alınarak kişisel veriler Üçüncü Kişiler’e aktarılabilmektedir:
              </Typography>
              <Typography paragraph>
                <strong>A)</strong> Kişisel verilerin aktarılmasına ilişkin
                ilgili faaliyetin kanunlarda açıkça öngörülmesi,
                <br />
                <strong>B)</strong> Kişisel verilerin Şirket tarafından
                aktarılmasının bir sözleşmenin kurulması veya ifasıyla doğrudan
                doğruya ilgili ve gerekli olması,
                <br />
                <strong>C)</strong> Kişisel verilerin aktarılmasının Şirket’in
                hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması,
                <br />
                <strong>D)</strong> Kişisel verilerin veri sahibi tarafından
                alenileştirilmiş olması şartıyla, alenileştirme amacıyla sınırlı
                bir şekilde Şirket tarafından aktarılması,
                <br />
                <strong>E)</strong> Kişisel verilerin Şirket tarafından
                aktarılmasının Şirket’in veya veri sahibinin veya üçüncü
                kişilerin haklarının tesisi, kullanılması veya korunması için
                zorunlu olması,
                <br />
                <strong>F)</strong> Veri sahibinin temel hak ve özgürlüklerine
                zarar vermemek kaydıyla Şirket meşru menfaatleri için kişisel
                veri aktarımı faaliyetinde bulunulmasının zorunlu olması,
                <br />
                <strong>G)</strong> Fiili imkansızlık nedeniyle rızasını
                açıklayamayacak durumda bulunan veya rızasına hukuki geçerlilik
                tanınmayan kişinin kendisinin ya da bir başkasının hayatı veya
                beden bütünlüğünü koruması için zorunlu olması durumlarında
                kişisel veriler üçüncü kişilere aktarılabilmektedir.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                3.2 Özel Nitelikli Kişisel Verilerin Aktarılması
              </Typography>
              <Typography paragraph>
                Özel nitelikli kişisel veriler, kanuni yükümlülükler haricinde
                ve kanunların istisna tuttuğu haller dışında yurtiçi ve yabancı
                ülkelere aktarılmamaktadır.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                3.3 Kişisel Verilerin Aktarıldığı Kişi Kategorizasyonları
              </Typography>
              <Typography paragraph>
                BERLİNER GRUP EĞİTİM DANIŞMANLIK YAYINCILIK TURİZM SANAYİ VE
                TİCARET LİMİTED ŞİRKETİ, Kanun’un 8’inci ve 9’uncu maddelerine
                uygun olarak kişisel verileri aşağıda sıralanan alıcı grubu
                kategorilerine aktarabilmektedir:
              </Typography>
              <Typography paragraph>
                <strong>A)</strong> Tedarikçilere,
                <br />
                <strong>B)</strong> Yetkili Kamu Kurum ve Kuruluşlarına.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                4- KİŞİSEL VERİ SAHİPLERİNİN AYDINLATILMASI
              </Typography>
              <Typography paragraph>
                BERLİNER GRUP EĞİTİM DANIŞMANLIK YAYINCILIK TURİZM SANAYİ VE
                TİCARET LİMİTED ŞİRKETİ, kişisel veri sahiplerini, hangi
                amaçlarla kişisel verilerinin işlendiği, hangi amaçlarla
                kimlerle paylaşıldığı, hukuki sebebi ve veri sahiplerinin
                kişisel verilerinin işlenmesinde sahip olduğu hakları
                konularında, ilgili mevzuata uygun şekilde bilgilendirir.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                5- KİŞİSEL VERİ ENVANTER PARAMETRELERİ
              </Typography>
              <Typography paragraph>
                BERLİNER GRUP EĞİTİM DANIŞMANLIK YAYINCILIK TURİZM SANAYİ VE
                TİCARET LİMİTED ŞİRKETİ, muhasebe, insan kaynakları, satın alma,
                destek hizmetleri, satış pazarlama, üretim iş süreçleriyle
                ilgili çalışan, çalışan adayı, stajyer, hissedar/ortak,
                potansiyel ürün veya hizmet alıcısı, tedarikçi yetkilisi,
                tedarikçi çalışanı, ürün veya hizmet alan kişi, ziyaretçiden
                oluşan kişisel veri sahipliklerine ait veri kategorileri ve
                kişisel verileri bu politikada belirtilen kişisel veri işleme
                amaçlarına bağlı kalarak işlemektedir.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                6- KİŞİSEL VERİLERİN KORUNMASI
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                6.1 Kişisel Verilerin Güvenliğinin Sağlanması
              </Typography>
              <Typography paragraph>
                BERLİNER GRUP EĞİTİM DANIŞMANLIK YAYINCILIK TURİZM SANAYİ VE
                TİCARET LİMİTED ŞİRKETİ, kişisel verilerin hukuka aykırı
                açıklanmasını, erişimini, aktarılmasını veya başka şekillerde
                oluşabilecek güvenlik sorunlarını önlemek için, kişisel verinin
                niteliğine göre, Kanun’un 12. maddesinde öngörülen gerekli
                önlemleri almaktadır. Gerekli kişisel veri güvenlik seviyesini
                sağlamak için rehberlere uygun tedbirler almaktadır.
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                6.2 Özel Nitelikli Kişisel Verilerin Korunması
              </Typography>
              <Typography paragraph>
                Özel nitelikte olan, kılık ve kıyafet, sağlık, ceza mahkûmiyeti
                ve güvenlik tedbirleriyle ilgili veriler ile biometrik verilerin
                korunmasına yönelik alınan önlemler özenle uygulanmaktadır.
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                6.3 Kişisel Verilerin Korunmasına İlişkin Alınan Önlemler
              </Typography>
              <Typography paragraph>
                Gerekli teknik ve idari tedbirler alınmakta, denetimler
                yapılmakta, bilinçlendirme ve eğitim faaliyetleri
                gerçekleştirilmektedir. İşlenen verilerin kanuni olmayan
                yollarla ele geçirilmesi durumunda ilgili kişi ve kurumlara
                haber verilmektedir.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                7- KİŞİSEL VERİLERİN SAKLANMASI VE İMHASI
              </Typography>
              <Typography paragraph>
                Kişisel veriler, işleme amacı için gerekli olan süre kadar ve
                ilgili mevzuatta öngörülen en az süre kadar muhafaza edilir.
                Belirlenen saklama sürelerinin sonunda, veriler belirlenen
                yöntemlerle imha edilir.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                8- KİŞİSEL VERİ SAHİPLERİNİN AYDINLATILMASI
              </Typography>
              <Typography paragraph>
                Kişisel veri sahipleri, verilerin elde edilmesi sırasında,
                verilerin hangi amaçla işleneceği, kimlere ve hangi amaçla
                aktarılabileceği, toplamanın yöntemi ve hukuki sebebi ile sahip
                oldukları haklar hakkında aydınlatılır. Kişisel veri sahibinin
                bilgi talep etmesi durumunda, gerekli bilgilendirme yapılır.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                9- KİŞİSEL VERİ SAHİBİNİN HAKLARI VE BU HAKLARIN KULLANILMASI
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                9.1 Kişisel Veri Sahibinin Hakları
              </Typography>
              <Typography paragraph>
                Kişisel verilere ilişkin olarak veri sahibinin kullanabileceği
                kanuni haklar aşağıda sayılmaktadır:
              </Typography>
              <Typography paragraph>
                <strong>A)</strong> Kişisel verilerinin işlenip işlenmediğini
                öğrenme,
                <br />
                <strong>B)</strong> Kişisel verileri işlenmişse buna ilişkin
                bilgi talep etme,
                <br />
                <strong>C)</strong> Kişisel verilerinin işlenme amacını ve
                bunların amacına uygun kullanılıp kullanılmadığını öğrenme,
                <br />
                <strong>D)</strong> Kişisel verilerinin yurt içinde veya yurt
                dışında aktarıldığı üçüncü kişileri öğrenme,
                <br />
                <strong>E)</strong> Kişisel verilerinin eksik veya yanlış
                işlenmiş olması halinde bunların düzeltilmesini isteme ve bu
                kapsamda yapılan işlemin kişisel verilerinin aktarıldığı üçüncü
                kişilere bildirilmesini isteme,
                <br />
                <strong>F)</strong> Kanun ve ilgili diğer kanun hükümlerine
                uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren
                sebeplerin ortadan kalkması halinde kişisel verilerinin
                silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan
                işlemin kişisel verilerinin aktarıldığı üçüncü kişilere
                bildirilmesini isteme,
                <br />
                <strong>H)</strong> İşlenen verilerinin münhasıran otomatik
                sistemler vasıtasıyla analiz edilmesi suretiyle aleyhine bir
                sonucun ortaya çıkmasına itiraz etme,
                <br />
                <strong>I)</strong> Kişisel verilerinin kanuna aykırı olarak
                işlenmesi sebebiyle zarara uğraması halinde zararın
                giderilmesini talep etme.
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                9.2 Veri Sahibinin Haklarını İleri Süremeyeceği Haller
              </Typography>
              <Typography paragraph>
                Kanun’un 28’inci maddesinde sayılı hallerde, kişisel veri
                sahipleri aşağıdaki durumlar hariç, bölüm 9.1’de sayılan
                haklarını ileri süremezler:
              </Typography>
              <Typography paragraph>
                <strong>A)</strong> Kişisel verilerin resmi istatistik ile
                anonim hale getirilmek suretiyle işlenmesi,
                <br />
                <strong>B)</strong> Kişisel verilerin sanat, tarih, edebiyat
                veya bilimsel amaçlarla ya da ifade özgürlüğü kapsamında
                işlenmesi,
                <br />
                <strong>C)</strong> Kişisel verilerin kanunla görev ve yetki
                verilmiş kamu kurum ve kuruluşları tarafından yürütülen
                faaliyetler kapsamında işlenmesi,
                <br />
                <strong>D)</strong> Kişisel verilerin yargı makamları veya infaz
                mercileri tarafından işlenmesi.
              </Typography>
              <Typography paragraph>
                Ayrıca, Kanun’un 28’inci maddesinin 2’nci fıkrası gereği,
                zararın giderilmesini talep etme hakkı hariç, diğer haklarını şu
                durumlarda ileri süremezler:
              </Typography>
              <Typography paragraph>
                <strong>A)</strong> Suç işlenmesinin önlenmesi veya suç
                soruşturması için gerekli olduğunda,
                <br />
                <strong>B)</strong> Alenileştirilmiş kişisel verilerin
                işlenmesi,
                <br />
                <strong>C)</strong> Denetleme veya düzenleme görevlerinin ve
                disiplin soruşturma veya kovuşturması için gerekli olduğunda,
                <br />
                <strong>D)</strong> Devletin ekonomik ve mali çıkarlarının
                korunması için gerekli olduğunda.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                9.3 Kişisel Veri Sahiplerinin Haklarını Kullanması
              </Typography>
              <Typography paragraph>
                Kişisel veri sahipleri bölüm 9.1’de (“Kişisel Veri Sahibinin
                Hakları”) sayılı haklarına ilişkin taleplerini “BERLİNER GRUP
                EĞİTİM DANIŞMANLIK YAYINCILIK TURİZM SANAYİ VE TİCARET LİMİTED
                ŞİRKETİ Anonim Şirketi Veri Sahibi Başvuru Formu”nu doldurarak
                şahsen elden teslim, posta yoluyla ya da BERLİNER GRUP EĞİTİM
                DANIŞMANLIK YAYINCILIK TURİZM SANAYİ VE TİCARET LİMİTED
                ŞİRKETİ'ne bildirmiş olduğunuz ve sistemimizde kayıtlı bulunan
                elektronik posta adresinizi kullanmak suretiyle
                iletebileceklerdir.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                9.4 BERLİNER GRUP EĞİTİM DANIŞMANLIK YAYINCILIK TURİZM SANAYİ VE
                TİCARET LİMİTED ŞİRKETİ’nin Başvurulara Cevap Vermesi
              </Typography>
              <Typography paragraph>
                BERLİNER GRUP EĞİTİM DANIŞMANLIK YAYINCILIK TURİZM SANAYİ VE
                TİCARET LİMİTED ŞİRKETİ, kişisel veri sahibi tarafından
                yapılacak başvuruları etkin, hukuka ve dürüstlük kuralına uygun
                olarak sonuçlandırmak üzere gerekli her türlü idari ve teknik
                tedbirleri almaktadır. Başvuruları kabul edebileceği gibi
                gerekçesini açıklayarak reddedebilecektir. İlgili cevabını
                kişisel veri sahibine yazılı olarak veya elektronik ortamda
                bildirebilecektir. Talebin niteliğine göre en kısa sürede ve en
                geç 30 gün içinde ilgili talebi ücretsiz olarak
                sonuçlandıracaktır. Ancak, işlemin ayrıca bir maliyeti
                gerektirmesi halinde, ücret başvurana mevzuat gereği
                yansıtılacaktır.
              </Typography>
            </Box>
          </Modal>
        </Grid>
      </Grid>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {alertMessage}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
});

export default AddressForm;
