import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useImperativeHandle, forwardRef } from "react";

const PaymentForm = forwardRef((props, ref) => {
  //const [ssKabul, setSsKabul] = React.useState(false);

  //const handleSSChange = (event) => {
  //  setSsKabul(event.target.checked);
  //};

  const [alertOpen, setAlertOpen] = React.useState(false);

  const [alertMessage, setAlertMessage] = React.useState("");

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const [cardData, setCardData] = React.useState({
    cardName: "",
    cardNumber: "",
    expDate: "",
    cvv: "",
    // acceptSS removed from here
  });

  const handleInputChange = (e) => {
    const { id, value, checked, type } = e.target;

    setCardData((prevState) => {
      if (id === "cardNumber") {
        let onlyNums = value.replace(/\D/g, ""); // Remove all non-digits
        onlyNums = onlyNums.slice(0, 16); // Limit the length to 16 digits

        // Format the digits with spaces every 4 characters
        let formattedValue = onlyNums.replace(/(.{4})/g, "$1 ").trim();

        return {
          ...prevState,
          [id]: formattedValue,
        };
      } else if (id === "expDate") {
        let formattedValue = value.replace(/[^\d]/g, ""); // Remove non-digits first

        // Determine if user is trying to backspace after the slash
        const isBackspacingAfterSlash =
          formattedValue.length === 2 &&
          prevState.expDate.includes("/") &&
          prevState.expDate.length === 3;

        if (isBackspacingAfterSlash) {
          // Allow user to backspace the '/'
          formattedValue = formattedValue.slice(0, 1);
        } else if (formattedValue.length >= 2) {
          // Automatically insert slash after 2 digits
          formattedValue = `${formattedValue.slice(
            0,
            2
          )}/${formattedValue.slice(2, 4)}`;
        }

        return {
          ...prevState,
          [id]: formattedValue,
        };
      } else {
        // Handle other inputs as before
        return {
          ...prevState,
          [id]: type === "checkbox" ? checked : value,
        };
      }
    });
  };

  const validateData = () => {
    // Example validation: Ensure card number length and CVV
    if (cardData.cardNumber.length < 16) {
      setAlertMessage(`Kart numarası 16 basamaklı olmalıdır.`);
      setAlertOpen(true);
      return false;
    }
    if (cardData.cvv.length < 3 || cardData.cvv.length > 4) {
      setAlertMessage(`CVV 3 basamaklı olmalıdır.`);
      setAlertOpen(true);
      return false;
    }
    const expDateRegex = /^(0[1-9]|1[0-2])\/([0-9]{2})$/;
    if (!expDateRegex.test(cardData.expDate)) {
      setAlertMessage(`Son kullanma tarihi AA/YY formatında olmalıdır.`);
      setAlertOpen(true);
      return false;
    }

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear() % 100; // Last two digits of the year
    const currentMonth = currentDate.getMonth() + 1; // JavaScript months are 0-indexed
    const [expMonth, expYear] = cardData.expDate
      .split("/")
      .map((num) => parseInt(num, 10));

    if (
      expYear < currentYear ||
      (expYear === currentYear && expMonth < currentMonth)
    ) {
      setAlertMessage(`Son kullanma tarihi geçmiş olamaz.`);
      setAlertOpen(true);
      return false;
    }

    //if (!ssKabul) {
    //  setAlertMessage(`Satış sözleşmesini onaylamanız gerekmektedir.`);
    //  setAlertOpen(true);
    //  return false;
    //}

    return true;
  };

  const handleFormSubmit = () => {
    if (validateData()) {
      const ccData = {
        cardName: cardData.cardName,
        cardNumber: cardData.cardNumber.replace(/\s/g, ""),
        expDate: cardData.expDate,
        cvv: cardData.cvv,
        // Do not include the acceptSS boolean as it's not part of the actual CC data
      };

      return ccData;
    }
  };

  useImperativeHandle(ref, () => ({
    handleFormSubmit,
  }));

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Ödeme Metodu
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="cardName"
            label="Kart üzerindeki isim"
            fullWidth
            autoComplete="cc-name"
            variant="standard"
            value={cardData.cardName}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="cardNumber"
            label="Kart numarası"
            fullWidth
            autoComplete="cc-number"
            variant="standard"
            value={cardData.cardNumber}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="expDate"
            label="Son kullanma tarihi"
            fullWidth
            autoComplete="cc-exp"
            variant="standard"
            value={cardData.expDate}
            onChange={handleInputChange}
            placeholder="AA/YY"
            inputProps={{ maxLength: 5 }} // Restrict input length
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="cvv"
            label="CVV"
            helperText="Last three digits on signature strip"
            fullWidth
            autoComplete="cc-csc"
            variant="standard"
            // Indicate expected format with a placeholder
            value={cardData.cvv}
            onChange={handleInputChange}
          />
        </Grid>
        {/*<Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                name="acceptSS"
                checked={ssKabul}
                onChange={handleSSChange}
              />
            }
            label="Satış sözleşmesini okudum ve kabul ediyorum"
          />
          </Grid>*/}
      </Grid>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {alertMessage}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
});

export default PaymentForm;
