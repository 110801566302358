import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Link, useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { Grid, Container } from "@mui/material";

import { KeyboardArrowDown } from "@mui/icons-material";
import { KeyboardArrowUp } from "@mui/icons-material";

import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
} from "firebase/firestore/lite";
import { db, app } from "../firebaseConfig";

const isThisWeek = (date) => {
  const now = new Date();
  const firstDayOfWeek = new Date(now.setDate(now.getDate() - now.getDay()));
  const lastDayOfWeek = new Date(
    firstDayOfWeek.getTime() + 6 * 24 * 60 * 60 * 1000
  );
  return date >= firstDayOfWeek && date <= lastDayOfWeek;
};

const isThisMonth = (date) => {
  const now = new Date();
  return (
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  );
};

function Row({ row }) {
  // Placeholder for any additional logic, e.g., collapsible rows
  return (
    <TableRow hover>
      <TableCell>{row.id}</TableCell>
      <TableCell>{row.tc}</TableCell>
      <TableCell>{row.name}</TableCell>
      <TableCell>{row.surname}</TableCell>
      <TableCell>{row.studentId}</TableCell>
      <TableCell>{row.examId}</TableCell>
      <TableCell>
        <Typography
          sx={{
            color:
              row.paymentStatus === "Paid"
                ? "green"
                : row.paymentStatus === "Failed"
                ? "red"
                : "yellow",
            fontWeight: "bold",
          }}
        >
          {row.paymentStatus === "Paid"
            ? "Başarılı"
            : row.paymentStatus === "Failed"
            ? "Başarısız"
            : "Bekleniyor"}
        </Typography>
      </TableCell>

      <TableCell>
        {row.paymentDate == null ? row.registrationDate : row.paymentDate}
      </TableCell>
      <TableCell>{row.amount}</TableCell>
    </TableRow>
  );
}

Row.propTypes = {
  row: PropTypes.object.isRequired,
};

export default function AdminPayments() {
  const [totalSuccessful, setTotalSuccessful] = React.useState(0);
  const [thisMonthSuccessful, setThisMonthSuccessful] = React.useState(0);
  const [thisWeekSuccessful, setThisWeekSuccessful] = React.useState(0);
  const [totalRegistrations, setTotalRegistrations] = React.useState(0);
  const [thisMonthRegistrations, setThisMonthRegistrations] = React.useState(0);
  const [thisWeekRegistrations, setThisWeekRegistrations] = React.useState(0);

  const [paymentRows, setPaymentRows] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "Kayitlar"));
      let rows = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
          registrationDate: data.registrationDate?.toDate(), // Keep as Date object for sorting
          paymentDate: data.paymentDate?.toDate(), // Keep as Date object for sorting
        };
      });

      rows.sort((a, b) => {
        const dateA = a.paymentDate || a.registrationDate; // Use Date objects directly
        const dateB = b.paymentDate || b.registrationDate;
        return dateB - dateA; // Correct sorting of Date objects
      });

      rows = rows.map((row) => ({
        ...row,
        registrationDate: row.registrationDate.toLocaleString("tr-TR", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }),
        paymentDate: row.paymentDate
          ? row.paymentDate.toLocaleString("tr-TR", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            })
          : null,
      }));

      let total = 0;
      let month = 0;
      let week = 0;
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const registrationDate = data.registrationDate.toDate();

        if (data.paymentStatus === "Paid") {
          total++;
          if (isThisMonth(registrationDate)) {
            month++;
          }
          if (isThisWeek(registrationDate)) {
            week++;
          }
        }
      });

      setPaymentRows(rows);
      setTotalSuccessful(total);
      setThisMonthSuccessful(month);
      setThisWeekSuccessful(week);
      setTotalRegistrations(querySnapshot.size);
      setThisMonthRegistrations(
        querySnapshot.docs.filter((doc) =>
          isThisMonth(doc.data().registrationDate.toDate())
        ).length
      );
      setThisWeekRegistrations(
        querySnapshot.docs.filter((doc) =>
          isThisWeek(doc.data().registrationDate.toDate())
        ).length
      );
    };

    fetchData();
  }, []);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6">Toplam başarılı kayıtlar</Typography>
            <Typography variant="h4">{totalSuccessful}</Typography>
          </Paper>
        </Grid>
        {/* This Month's Successful Payments */}
        <Grid item xs={4}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6">Bu aylık başarılı kayıtlar</Typography>
            <Typography variant="h4">{thisMonthSuccessful}</Typography>
          </Paper>
        </Grid>
        {/* This Week's Successful Payments */}
        <Grid item xs={4}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6">Bu haftalık başarılı kayıtlar</Typography>
            <Typography variant="h4">{thisWeekSuccessful}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <TableContainer
            component={Paper}
            sx={{ maxHeight: "100%", overflow: "auto" }}
          >
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>TC</TableCell>
                  <TableCell>İsim</TableCell>
                  <TableCell>Soyisim</TableCell>
                  <TableCell>Öğrenci ID</TableCell>
                  <TableCell>Sınav ID</TableCell>
                  <TableCell>Ödeme Durumu</TableCell>
                  <TableCell>İşlem Tarihi</TableCell>
                  <TableCell>Tutar (TL)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paymentRows.map((row) => (
                  <Row key={row.id} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
}
